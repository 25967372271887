import React from 'react'
import TopHeaderUI from './TopHeader'
import useAuthentication from 'hooks/useAuthentication'

export default function TopHeader(props) {
  const {signOut, getUserData, checkIsUserLogged} = useAuthentication()
  const isUserLogged = checkIsUserLogged()
  if (isUserLogged) {
    const userData = getUserData()
    return <TopHeaderUI onLogout={signOut} userData={userData} {...props} />
  }
  return <TopHeaderUI {...props} />
}
