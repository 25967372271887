import {useContext, useCallback} from 'react'
import {UserContext} from 'contexts/UserContext'
import * as actions from 'contexts/UserActions'
import useApi from 'hooks/useApi'

export const TOKEN_KEY = 'sessionToken'

export default function useAuthentication() {
  const [state, dispatch] = useContext(UserContext)
  const api = useApi()
  // console.log({state})

  function removeToken() {
    try {
      localStorage.removeItem(TOKEN_KEY)
      sessionStorage.removeItem(TOKEN_KEY)
    } catch (error) {
      console.error(error)
    }
  }

  function saveToken(token, shouldPersist = false) {
    try {
      if (shouldPersist) {
        localStorage.setItem(TOKEN_KEY, token)
      } else {
        sessionStorage.setItem(TOKEN_KEY, token)
      }
    } catch (error) {
      console.error(error)
    }
  }

  // parametro in ingresso è l'eventuale token passato come query string
  const init = useCallback(
    async qsToken => {
      dispatch(actions.startInit())
      let token =
        localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY)
      if (qsToken) {
        if (token && token !== qsToken) {
          // se c'è un token nello storage ed è diverso da quello della qs, lo cancello e salvo quello della qs come temporaneo
          // (se è uguale non faccio nulla)
          removeToken()
          const shouldPersist = false
          saveToken(qsToken, shouldPersist)
        } else if (!token) {
          // se non c'è il token nello storage salvo direttamente quello della qs come temporaneo
          const shouldPersist = false
          saveToken(qsToken, shouldPersist)
        }
        // ad ogni modo se ho un token in query string faccio le operazioni su quello
        token = qsToken
      }
      if (!token) {
        dispatch(actions.finishInit(null, null))
        return
      }
      try {
        // call api to check token and get userData
        const userData = await api.getUserByToken(token)
        dispatch(actions.finishInit(token, userData || null))
      } catch (error) {
        // console.error(error)
        console.info(error.message)
        removeToken()
        dispatch(actions.finishInit(null, null))
      }
    },
    [api, dispatch]
  )

  const signIn = useCallback(
    async (email, password, shouldPersist = false) => {
      try {
        dispatch(actions.signInRequest())
        // call login api
        const {token} = await api.signIn(email, password)
        if (!token) {
          throw new Error('No token returned on login')
        }
        const userData = await api.getUserByToken(token)
        saveToken(token, shouldPersist)
        dispatch(actions.signSuccess(token, userData))
      } catch (error) {
        console.info(error)
        dispatch(actions.signError(error))
      }
    },
    [api, dispatch]
  )

  const signUp = useCallback(
    async (userData, password, shouldPersist = false) => {
      dispatch(actions.signUpRequest(userData))
      try {
        const {email1: email} = userData
        // request for signup
        await api.signUp(email, password, userData)
        const {token} = await api.signIn(email, password)
        const newUserData = await api.getUserByToken(token)
        saveToken(token, shouldPersist)
        dispatch(actions.signSuccess(token, newUserData))
      } catch (error) {
        console.error(error)
        dispatch(actions.signError(error))
      }
    },
    [api, dispatch]
  )

  const signOut = useCallback(() => {
    removeToken()
    dispatch(actions.signOut())
  }, [dispatch])

  const checkIsUserLogged = useCallback(() => {
    const {isInitDone, isFetching, token, userData, error} = state
    return isInitDone && !isFetching && !!token && !!userData && !error
  }, [state])

  const getUserData = useCallback(() => {
    if (checkIsUserLogged()) {
      return state.userData
    } else {
      return null
    }
  }, [checkIsUserLogged, state.userData])

  const getError = useCallback(() => {
    return state.error
  }, [state.error])

  const checkIsSignUpCompleted = useCallback(() => {
    const {signType} = state
    return checkIsUserLogged() && signType === actions.SIGN_TYPE_SIGN_UP
  }, [checkIsUserLogged, state])

  const checkIsSigningIn = () => {
    const {isFetching, signType} = state
    return isFetching && signType === actions.SIGN_TYPE_SIGN_IN
  }

  const checkIsSigningUp = () => {
    const {isFetching, signType} = state
    return isFetching && signType === actions.SIGN_TYPE_SIGN_UP
  }

  const resetErrors = () => {
    dispatch(actions.resetError())
  }

  return {
    init,
    signIn,
    signUp,
    signOut,
    checkIsSigningIn,
    checkIsSigningUp,
    getUserData,
    checkIsUserLogged,
    checkIsSignUpCompleted,
    getError,
    resetErrors,
  }
}
