import React, {useContext} from 'react'
import {AppContext} from '../../contexts/AppContext'
import useExtraServices from '../../hooks/useExtraServices'
import ExtraServicesUI from './ExtraServicesWrapper'

export default function ExtraServices() {
  const [state] = useContext(AppContext)
  const {
    extraServices: allExtraServices,
    fetchingExtraServices,
    step4ExtraServices: selectedExtraServices,
  } = state
  const {onSelectExtraService, onDeselectOptionalExtraServices} = useExtraServices()

  return (
    <ExtraServicesUI
      allExtraServices={allExtraServices}
      selectedExtraServices={selectedExtraServices}
      fetchingExtraServices={fetchingExtraServices}
      onSelectExtraService={onSelectExtraService}
      onDeselectOptionalExtraServices={onDeselectOptionalExtraServices}
    />
  )
}
