import React from 'react'
import {Alert, AlertTitle} from '@material-ui/lab'


export default function StepPaymentError(props) {
    const {title = 'Errore', message = 'Errore generico'} = props

    return (
        <Alert severity="error">
            <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert>
    )
}
