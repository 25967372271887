import React, {useState} from 'react'

const PaymentContext = React.createContext([{}, () => {}])

const PaymentProvider = props => {
  const [state, setState] = useState({
    partecipazione: 'privato',
    tipo: null,
    bankTransfer: false,
    paypalTransfer: false,
    updatingPayment: false,
    updatedPayment: false,
    updatePaymentError: null,
    confirming: false,
    confirmed: false,
    confirmError: null,
    paid: false,
    paying: false,
    payError: null,
    paypalClientId: null,
    paypalAnswered: false,
  })
  return (
    <PaymentContext.Provider value={[state, setState]}>
      {props.children}
    </PaymentContext.Provider>
  )
}

export {PaymentContext, PaymentProvider}
