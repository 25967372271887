import React from 'react'
import PropTypes from 'prop-types'
import PackageSelector from '../../components/PackageSelector'

export default function PackageSelectorWrapper({title}) {
    return (
        <PackageSelector
            title={title}
            groupsTitle="Pacchetto"
            turnsTitle="Seleziona periodo"
            enableStructures
            enableTurnPrice
        />
    )
}

PackageSelectorWrapper.propTypes = {
    title: PropTypes.string,
}
