import {useContext} from 'react'
import {AppContext} from 'contexts/AppContext'
import {PaymentContext} from 'contexts/PaymentContext'
import useApi from './useApi'

export function codeToParticipation(code) {
  return (code === '2' || code === '6') ? 'inps' : 'privato'
}

export default function usePayment() {
  const [appState] = useContext(AppContext)
  const [state, setState] = useContext(PaymentContext)
  const api = useApi()

  const handleTipo = tipo => {
    const partecipazione = codeToParticipation(appState.step2Participation)
    setState(state => ({...state, tipo, partecipazione}))
  }

  const handleBankTransfer = bankTransfer => {
    setState(state => ({...state, bankTransfer}))
  }

  const handlePaypalTransfer = paypalTransfer => {
    setState(state => ({...state, paypalTransfer}))
  }

  const updatePayment = async () => {
    try {
      setState(state => ({
        ...state,
        updatingPayment: true,
        updatePaymentError: null,
        updatedPayment: false,
      }))

      const participation = codeToParticipation(appState.step2Participation)
      await api.updatePaymentData(
        appState.practiceId,
        participation,
        state.tipo || 'sicuro'
      )

      setState(state => ({
        ...state,
        partecipazione: participation,
        updatingPayment: false,
        updatedPayment: true,
      }))
      postFlutterResultMessage(true);
    } catch (error) {
      setState(state => ({
        ...state,
        updatingPayment: false,
        updatePaymentError: error,
      }))
      postFlutterResultMessage(false, error.message, error.code);
    }
  }

  function postFlutterResultMessage(success, errMsg=false, errCode=0) {
    try {
      if (
          typeof window.flutterMessageHandler !== 'undefined'
          && window.flutterMessageHandler !== null
      ) {
        console.log('posting message to flutter');
        let payload = {success: success};
        if (errMsg) payload['errMsg'] = errMsg;
        if (errCode) payload['errCode'] = errCode;
        window.flutterMessageHandler.postMessage(JSON.stringify(payload));
      } else {
        console.log('flutter message handler unavailable')
      }
    } catch (e){
      console.error(e);
    }
  }

  const confirm = async () => {
    try {
      setState(state => ({
        ...state,
        confirming: true,
        confirmed: false,
        confirmError: null,
      }))
      await api.paymentConfirm(appState.practiceId)
      setState(state => ({...state, confirming: false, confirmed: true}))
    } catch (error) {
      setState(state => ({...state, confirming: false, confirmingError: error}))
    }
  }

  const startPayment = () => {
    setState(state => ({...state, paying: true, paid: false, payError: null}))
  }

  const finishPaymentWithSuccess = () => {
    setState(state => ({...state, paying: false, paid: true}))
  }

  const finishPaymentWithError = error => {
    setState(state => ({...state, paying: false, paid: false, payError: error}))
  }

  const finalizeBankTransferOrder = async () => {
    try {
      if (!state.paying) {
        startPayment()
      }
      await api.payPractice(
        state.bankTransfer,
        state.paypalTransfer,
        // nonce,
        null,
        appState.practiceId,
        appState.division
      )
      finishPaymentWithSuccess()
    } catch (error) {
      finishPaymentWithError(error)
      throw error
    }
  }

  const getPaypalClientId = async () => {
    try {
      const response = await api.getPaypalClientId(appState.practiceId)
      // console.log({response})
      const paypalClientId = response['CLIENT_ID']
      setState(state => ({...state, paypalClientId}))
    } catch (error) {
      console.error(error)
    }
  }

  const finalizePaypalOrder = async orderID => {
    if (!state.paying) {
      setState(state => ({...state, paying: true, payError: null, paid: false}))
    }
    try {
      const response = await api.finalizePaypalOrder(
        appState.practiceId,
        orderID
      )
      console.log({response})
      setState(state => ({...state, paid: true, paying: false}))
      return response
    } catch (error) {
      console.error(error)
      setState(state => ({...state, paying: false, payError: error}))
    }
  }

  const markAsPaymentError = async (error = {}) => {
    if (typeof error === 'string') {
      setState(state => ({...state, paying: false, payError: {message: error}}))
    } else if (typeof error === 'object') {
      error.message =
        error.message || 'Errore generico durante le operazioni di pagamento'
      setState(state => ({...state, paying: false, payError: error}))
    } else {
      const payError = {
        message: 'Errore generico durante le operazioni di pagamento',
        innerError: error,
      }
      setState(state => ({...state, paying: false, payError}))
    }
    try {
      await api.markPaymentError(appState.practiceId, error)
    } catch (error) {
      console.error('impossibile salvare errore della pratica')
      console.error(error)
    }
  }

  return {
    handleTipo,
    handleBankTransfer,
    handlePaypalTransfer,
    updatePayment,
    confirm,
    startPayment,
    finishPaymentWithSuccess,
    finishPaymentWithError,
    finalizeBankTransferOrder,
    getPaypalClientId,
    finalizePaypalOrder,
    markAsPaymentError,
  }
}
