import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types'
import Notice from 'components/Notice'
import {getBookingFormNotice} from "../../specificUtils/api";

export default function StepCustomerDataNotice(props) {
    const [notice, setNotice] = useState();
    const {division} = props;

    const fetchData = async () => {
        try {
            const response = await getBookingFormNotice(division);
            if (response && response.data && response.data.enabled) {
                setNotice(response.data.text);
            }
        } catch (error) {
            console.error(error.response);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return notice ? (
        <Notice>
            <div dangerouslySetInnerHTML={{__html: notice}}/>
            {/*{division === '1' ? (*/}
            {/*    <React.Fragment>*/}
            {/*        <h2 className={classes.header_center}>*/}
            {/*            MODULO RISERVATO ALLE <br/>*/}
            {/*            RIPROTEZIONI PARTECIPANTI BANDO INPS E PRIVATI <br/>*/}
            {/*            <br/>*/}
            {/*            <u>*/}
            {/*                **ATTENZIONE NON TENERE CONTO DEGLI IMPORTI INDICATI NEL RIEPILOGO*/}
            {/*                LATERALE E NEL SALDO FINALE***/}
            {/*            </u>{' '}*/}
            {/*            <br/>*/}
            {/*            <br/>*/}
            {/*            EVENTUALI DIFFERENZE PER LA NUOVA PRENOTAZIONE SONO ESCLUSIVAMENTE*/}
            {/*            QUELLE INDICATE NELLA EMAIL CHE HAI RICEVUTO E VERRANNO COMUNQUE*/}
            {/*            EVIDENZIATE NEL NUOVO CONTRATTO CHE VI INVIEREMO DOPO LA SCELTA DEL*/}
            {/*            NUOVO SOGGIORNO*/}
            {/*        </h2>*/}
            {/*    </React.Fragment>*/}
            {/*) : (*/}
            {/*    <React.Fragment>*/}
            {/*        <h3 className={classes.header}>*/}
            {/*            Tutti i pacchetti Giocamondo sono stati approvati dall’INPS per il*/}
            {/*            2021.*/}
            {/*        </h3>*/}
            {/*        <p>*/}
            {/*            Prenotando subito il tuo soggiorno 2021 avrai diritto a numerosi*/}
            {/*            vantaggi:*/}
            {/*        </p>*/}
            {/*        <ul>*/}
            {/*            <li>Prezzi in promozione</li>*/}
            {/*            <li>Garanzie Giocamondo Safe incluse</li>*/}
            {/*            <li>*/}
            {/*                Puoi bloccare subito il tuo posto ed iniziare a pagare in 12*/}
            {/*                comode rate*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*        <p>*/}
            {/*            <strong>Allo step 4:</strong> potrai scegliere la modalità di*/}
            {/*            pagamento che preferisci.*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            <strong>Allo step 5:</strong>*/}
            {/*            <br/>*/}
            {/*            <strong>*/}
            {/*                SE SEI VINCITORE DEL CONTRIBUTO ESTATE INPSIEME 2021:*/}
            {/*            </strong>*/}
            {/*            <br/>*/}
            {/*            potrai decidere se pagare subito (tutto il saldo o un piccolo*/}
            {/*            acconto), bloccando immediatamente il tuo posto.*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            <strong>*/}
            {/*                <u>*/}
            {/*                    NB: il nostro modulo calcolerà automaticamente il tuo preventivo*/}
            {/*                </u>*/}
            {/*            </strong>*/}
            {/*            che potrai sempre consultare nella colonna{' '}*/}
            {/*            <strong>"riepilogo prenotazione"</strong>. Il tuo saldo sarà*/}
            {/*            definito in base all’ISEE indicato in relazione al contributo INPS*/}
            {/*            spettante.*/}
            {/*        </p>*/}
            {/*    </React.Fragment>*/}
            {/*)}*/}
        </Notice>
    ) : <></>
}
StepCustomerDataNotice.propTypes = {
    division: PropTypes.string.isRequired,
}
