import React, {useState, useEffect} from 'react'
import ModalAlertError from './ModalAlertError'
import ModalAlertDefault from './ModalAlertDefault'

export default function ModalAlert(props) {
  const {
    title,
    message,
    okLabel = 'OK',
    isOpen = false,
    onClose,
    error = false,
  } = props
  const [open, setOpen] = useState(isOpen)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const handleClose = () => {
    setOpen(false)
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  const newProps = {
    title,
    message,
    okLabel,
    open,
    onClose: handleClose,
  }

  if (error) {
    return <ModalAlertError {...newProps} />
  } else {
    return <ModalAlertDefault {...newProps} />
  }
}
