import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Notice from '../../../components/Notice'
import {getBookingFormNotice} from "../../specificUtils/api";


export default function StepCustomerDataNotice(props) {
    const [notice, setNotice] = useState();
    const {division} = props;

    const fetchData = async () => {
        try {
            const response = await getBookingFormNotice(division);
            if (response && response.data && response.data.enabled) {
                setNotice(response.data.text);
            }
        } catch (error) {
            console.error(error.response);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return notice ? (
        <Notice>
            <div dangerouslySetInnerHTML={{__html: notice}}/>
            {/*<h3 className={classes.header}>*/}
            {/*    Tutti i pacchetti Anni di Argento sono conformi al bando INPS 2021*/}
            {/*</h3>*/}

            {/*{division === '3' ? (*/}
            {/*    <React.Fragment>*/}
            {/*        <p>*/}
            {/*            Solo con Anni di Argento <strong>hai sempre la garanzia</strong> di avere:*/}
            {/*        </p>*/}
            {/*        <ul>*/}
            {/*            <li><strong>Operatore dell’agenzia h24</strong> residente in hotel con il gruppo</li>*/}
            {/*            <li><strong>Assicurazione Medico - Bagaglio sempre inclusa</strong> con tutela ed assistenza*/}
            {/*                medica anche in caso di COVID*/}
            {/*            </li>*/}
            {/*            <li><strong>Rispetto di tutti i protocolli di sicurezza COVID</strong> nei viaggi, in hotel e*/}
            {/*                nelle escursioni*/}
            {/*            </li>*/}
            {/*            <li><strong>Da 1 a 3 escursioni incluse a settimana</strong> in tutti i pacchetti</li>*/}
            {/*            <li><strong>Hotel 3*s / 4* visitati e selezionati per accessibilità, posizione,*/}
            {/*                qualità</strong> delle recensioni e livello dei servizi offerte*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*        <p>*/}
            {/*            <strong>Prenotando subito</strong> il tuo soggiorno 2021 avrai la <strong>garanzia di posto*/}
            {/*            bloccato nella località e nel periodo scelto!</strong>*/}
            {/*        </p>*/}
            {/*        <p className={classes.header}>*/}
            {/*            <strong>Nella sezione 5 del form potrai scegliere la modalità di pagamento tra:</strong> <br/>*/}
            {/*            <ul>*/}
            {/*                <li><strong>UNICA SOLUZIONE:</strong><br/>*/}
            {/*                    Fermi il tuo soggiorno subito versando una caparra di € 100,00 che sarà scalata dal*/}
            {/*                    saldo finale mentre il residuo ti sarà richiesto entro il 20 del mese antecedente la tua*/}
            {/*                    partenza<br/></li>*/}
            {/*                <li><strong>PRENOTA OGGI E PAGA DOPO</strong><br/>*/}
            {/*                    Fermi il tuo soggiorno subito versando una caparra di € 100,00 mentre il saldo potrai*/}
            {/*                    iniziare a pagarlo da settembre suddiviso in 12 rate che, in qualunque momento, potrai*/}
            {/*                    decidere di liquidare anticipatamente*/}
            {/*                </li>*/}
            {/*            </ul>*/}
            {/*        </p>*/}

            {/*    </React.Fragment>*/}
            {/*) : (*/}
            {/*    <React.Fragment>*/}
            {/*        <p>Segnaposto</p>*/}
            {/*    </React.Fragment>*/}
            {/*)}*/}

        </Notice>
    ) : <></>
}
StepCustomerDataNotice.propTypes = {
    division: PropTypes.string.isRequired
}
