import React, {useContext} from 'react'
import {AppContext} from '../../contexts/AppContext'
import DisabilityUI from './Disability'

export default function Disability() {
  const [state, setState] = useContext(AppContext)
  const {
    step3Handicap,
    step3DisabilitiesType,
    step3DisabilitiesHelperType,
    step3DisabilitiesHelper,
    step3DisabilitiesHelperSameRoom,
    step3DisabilitiesNotes,
    errors,
    applicationType,
  } = state
  const handleChange = label => event => {
    const {value} = event.target
    setState(state => ({...state, [label]: value}))
  }
  const handleFlagChange = label => event => {
    const {value} = event.target
    if (value === 'si') {
      setState(state => ({...state, [label]: value}))
    } else {
      switch (label) {
        case 'step3Handicap':
          setState(state => ({
            ...state,
            step3Handicap: value,
            step3DisabilitiesType: null,
            step3DisabilitiesHelperType: null,
            step3DisabilitiesHelper: null,
          }))
          break
        default:
          setState(state => ({...state, [label]: value}))
          break
      }
    }
  }
  return (
    <DisabilityUI
      errors={errors}
      step3Handicap={step3Handicap}
      step3DisabilitiesType={step3DisabilitiesType}
      step3DisabilitiesHelperType={step3DisabilitiesHelperType}
      step3DisabilitiesHelper={step3DisabilitiesHelper}
      step3DisabilitiesHelperSameRoom={step3DisabilitiesHelperSameRoom}
      step3DisabilitiesNotes={step3DisabilitiesNotes}
      onChange={handleChange}
      onFlagChange={handleFlagChange}
      assitanceExtraQuestions={applicationType === 'ada'}
    />
  )
}
