import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import {participationsLookup} from '../../specificUtils/configs'
import Typography from '@material-ui/core/Typography'
import Voucher from './Voucher'

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
    },
    group: {
        margin: theme.spacing(1, 0),
    },
    textField8: {
        width: '62.666667%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    textField12: {
        width: '100%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
}))

export default function StepBonusCorporate(props) {
    const {
        step2WelfareType,
        step2AziendaConvenzionataNote,
        onEventChange,
        step1ChildFiscalCode,
        step2VoucherCode,
    } = props
    const classes = useStyles()
    return (
        <>
            <div className="box-light boxed">
                <Grid container>
                    <Grid item xs={12}>
                        {[participationsLookup.AZIENDA_CONVENZIONATA].includes(
                            step2WelfareType
                        ) && (
                            <>
                                <Typography variant="h5">
                                Inserisci le indicazioni per il rimborso Welfare / Portale Welfare
                                </Typography>
                                { /*
                                <Typography variant="body1">
                                    Ai fini della verifica dell'appartenenza all'Azienda
                                    Convenzionata la Giocamondo potrebbe richiedere ulteriori
                                    documenti a completamento della prenotazione.
                                </Typography>
                                */ }
                                <TextField
                                    id="code-azienda-convenzionata-note"
                                    label="Inserisci la dicitura rimborso Welfare / nome del tuo portale Welfare"
                                    className={classes.textField12}
                                    value={step2AziendaConvenzionataNote}
                                    onChange={onEventChange('step2AziendaConvenzionataNote')}
                                    margin="normal"
                                    multiline
                                    //rows="4"
                                />
                            </>
                        )}
                        <Voucher
                            step1ChildFiscalCode={step1ChildFiscalCode}
                            onChange={onEventChange}
                            value={step2VoucherCode}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

StepBonusCorporate.propTypes = {
    step2WelfareType: PropTypes.string,
    step2AziendaConvenzionataNote: PropTypes.string,
    onEventChange: PropTypes.func.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
}
