import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {
    bonusTypes,
    participationsLookup,
} from '../../specificUtils/configs'
import Coupon from './Coupon'

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
    },
    textField4: {
        width: '32.333333%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    menu: {
        width: 200,
    },
    checkboxRow: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 16,
    },
}))

export default function StepBonusInpsieme(props) {
    const {
        // division,
        inpsServices,
        step2Participation,
        step2InpsServiceId,
        step2BonusName,
        onEventChange,
        onBonusRequest,
        step2Coupon,
        step2BonusType,
        variablePracticeInpsNumber,
    } = props
    const errors = props.errors || {}
    const classes = useStyles()
    const isNewInps = step2Participation === participationsLookup.INPSNUOVO
    const isInps = step2Participation === participationsLookup.INPSIEME


    //const title = isNewInps
    const title = isInps
        ? 'Bando INPSieme 2024'
        : 'Bando INPSieme 2024'

    //const description = isNewInps
    const description = isInps
        ? "Indica l'ISEE 2024 (domanda posta per fini statistici)"
        : "Indica l'ISEE 2024 (domanda posta per fini statistici)"



    return (
        <React.Fragment>
            <div className="box-light boxed">
                <Typography variant="h6">{title}</Typography>
                <Typography variant="body1">{description}</Typography>

                <TextField
                    id="inps-contributo"
                    select
                    error={
                        errors && errors.step2InpsServiceId
                            ? 'Indicazione contributo obbligatoria *'
                            : null
                    }
                    className={classes.textField4 + ' select col-xs-12'}
                    value={step2InpsServiceId}
                    onChange={onEventChange('step2InpsServiceId')}
                    SelectProps={{
                        native: true,
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    helperText="Indica l'ISEE 2024 (domanda posta per fini statistici)">
                    <option value="">Seleziona ISEE</option>
                    {inpsServices.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
            </div>
            <br/>
            <div className="box-light boxed">
                <Typography variant="h6">Numero pratica Bando INPS (sarà disponibile all'interno della tua area riservata INPS solo dopo aver ricevuto comunicazione sull'idoneità della tua pratica)</Typography>
                <Typography variant="body1">Ti ricordiamo che, se non disponi di questo numero al momento della prenotazione avrai la possibilità di inserirlo successivamente all'interno dell'Area Giocamondo Club. Tale informazione sarà fondamentale per verificare il tuo posizionamento nella graduatoria e gestire al meglio la tua pratica INPS.</Typography>
                    <TextField
                        id="variablePracticeInpsNumber"
                        label=""
                        className={classes.textField6}
                        value={variablePracticeInpsNumber}
                        onChange={onEventChange('variablePracticeInpsNumber')}
                        helperText={errors && errors.variablePracticeInpsNumber && 'Il codice deve contenere solo numeri e lettere e dev\'essere formato da 15 caratteri'}
                        margin="normal"
                        error={errors && errors.variablePracticeInpsNumber}
                    />
            </div>
            <br/>
            <div className="box-light boxed">
                <Grid
                    container
                    spacing={3}
                    className="panel"
                    style={{paddingRight: 16}}>
                    <Grid item xs={12} sm={3} className="bonus-wrapper">
                        <Typography variant="h6">Bonus</Typography>
                        <img
                            src="https://t-ec.bstatic.com/static/img/genius/genius_trial/tier-gift/1c8d6deaaabcdc179a20aefe503b0374b6ff096a.png"
                            height={100}
                            alt="bonus"
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <div className="light-box">
                            <Typography variant="h5">In gruppo costa meno</Typography>
                            <p>
                            Organizza la tua vacanza di gruppo e approfitta di sconti esclusivi! <strong>Più siete, più risparmiate:</strong>
                            <br/>
                            Se vi state iscrivendo insieme ad amici per la stessa destinazione, questa promozione prevede 3 fasce di sconto in base al numero di iscritti al vostro gruppo:
                            </p>
                            <ul>
                                <li><strong>Gruppi di 5: sconto di € 30 a persona.</strong></li>
                                <li><strong>Gruppi di 10: sconto fino a € 50 a persona</strong></li>
                                <li><strong>Gruppi di 15: sconto fino a € 70 a persona</strong></li>
                            </ul>
                            <p>Regole per l'Offerta:</p>
                                <ol>
                                    <li>Indicate il nome del capogruppo alla prenotazione.</li>
                                    <li>Un unico referente invii via email la lista dei partecipanti a <a href="mailto:soggiorni@giocamondo.it" title="Invia lista dei partecipanti">soggiorni@giocamondo.it</a></li>
                                    <li>Completate le prenotazioni entro una settimana dalla prima registrazione.</li>
                                    <li>Offerta valida solo per soggiorni di gruppo, non cumulabile con altre promozioni.</li>
                                </ol>
                            <p></p>
                            <Grid container className={classes.checkboxRow}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={step2BonusType === bonusTypes.GRUPPO}
                                                onChange={onBonusRequest(bonusTypes.GRUPPO)}
                                            />
                                        }
                                        label="Voglio usufruire del Bonus Gruppi"
                                    />
                                </Grid>
                                {step2BonusType === bonusTypes.GRUPPO && (
                                    <Grid item>
                                        <TextField
                                            id="cognome"
                                            label="Cognome e Nome Referente"
                                            className={classes.textField}
                                            value={step2BonusName}
                                            onChange={onEventChange('step2BonusName')}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </div>

                        <div className="light-box">
                            <Typography variant="h5">Bonus Porta un amico</Typography>
                            <p><strong>Hai degli amici che non partecipano al Bando Inpsieme che vogliono partire con te?  <br/>
                            Puoi scegliere il Bonus Porta un amico e ricevere sconti sulla tua prenotazione e su quella dei tuoi amici.</strong></p>

                            <Grid container className={classes.checkboxRow}>
                                <Grid item>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={step2BonusType === bonusTypes.AMICO}
                                        onChange={onBonusRequest(bonusTypes.AMICO)}
                                    />
                                    }
                                    label="Voglio usufruire del Bonus Porta un amico"
                                />
                                </Grid>
                                {step2BonusType === bonusTypes.AMICO && (
                                <Grid item>
                                    <TextField
                                    id="cognome"
                                    label="Cognome e Nome"
                                    className={classes.textField}
                                    value={step2BonusName}
                                    onChange={onEventChange('step2BonusName')}
                                    />
                                </Grid>
                                )}
                            </Grid>
                        </div>

                        <div className="light-box">
                        <Typography variant="h5">In doppia è meglio</Typography>
                        <p>
                        Se condividi la camera con un’altra persona non appartenente al tuo nucleo familiare (Stato di Famiglia differente), potrai beneficiare di un doppio vantaggio: ti verrà applicato uno sconto di € 100,00 a camera ed inoltre non avrai il supplemento singola da pagare risparmiando di fatto fino a un massimo di € 500,00.
                        </p>
                        <Grid container className={classes.checkboxRow}>
                            <Grid item>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={step2BonusType === bonusTypes.DOPPIA}
                                    onChange={onBonusRequest(bonusTypes.DOPPIA)}
                                />
                                }
                                label="In doppia è meglio"
                            />
                                </Grid>
                            </Grid>
                        </div>

                        <p>
                        Le promozioni non sono cumulabili tra di loro. In fase di prenotazione è obbligatorio
indicare a quale promozione si vuole aderire.
                        </p>

                    </Grid>
                </Grid>
            </div>
            <br/>
            <Coupon onChange={onEventChange('step2Coupon')} value={step2Coupon}/>
        </React.Fragment>
    )
}

StepBonusInpsieme.propTypes = {
    inpsServices: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    participations: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    step2bonuses: PropTypes.arrayOf(
        PropTypes.shape({
            bonustype: PropTypes.string.isRequired,
        })
    ),
    step2Participation: PropTypes.string,
    step2InpsServiceId: PropTypes.string,
    step2Grade: PropTypes.string,
    step2InpsServiceFlag: PropTypes.bool,
    step2BonusRequested: PropTypes.bool,
    step2BonusSurname: PropTypes.string,
    step2BonusName: PropTypes.string,
    step2Coupon: PropTypes.string,
    onEventChange: PropTypes.func.isRequired,
    onSimpleCheckboxChange: PropTypes.func.isRequired,
    onBonusRequest: PropTypes.func.isRequired,
}
