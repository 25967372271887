import React from 'react'
import Sidebar from 'components/Sidebar'
import {stepsByName} from '../specificUtils/configs'

export default function SidebarWrapper() {
    return (
        <Sidebar
            groupTitle="Durata permanenza"
            packageTitle="Periodo di partenza"
            departureTitle="Città di partenza"
            summaryStepIndex={stepsByName.RECAP}
            enableTagManager
        />
    )
}
