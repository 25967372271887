import React from 'react'
import PropTypes from 'prop-types'
import PackageSelector from '../../components/PackageSelector'

export default function PackageSelectorWrapper({title}) {
  return (
    <PackageSelector
      title={title}
      groupsTitle="Durata permanenza all'estero"
      turnsTitle="Periodo di partenza"
      noTurnsText="Non ci sono periodi di partenza disponibili per questa durata"
      enableStructures
      enableProgramTypologies
      enableCatalogs
    />
  )
}

PackageSelectorWrapper.propTypes = {
  title: PropTypes.string,
}
