import React, {useState, useEffect, useContext} from 'react'
import './App.css'
import MainApp from 'main/MainApp'
import HspApp from 'hsp/HspApp'
import DocentiApp from 'docenti/DocentiApp'
import AdaApp from 'ada/AdaApp'
import Loader from 'components/Loader'
import {AppProvider, AppContext} from 'contexts/AppContext'
import {PackageSelectorProvider} from 'contexts/PackageSelectorContext'
import {SpendingProvider} from 'contexts/SpendingContext'
import {UserProvider, UserContext} from 'contexts/UserContext'
import {PaymentProvider} from 'contexts/PaymentContext'
import usePackageSelectorInit from 'hooks/usePackageSelectorInit'
import useAuthentication from 'hooks/useAuthentication'
import usePrecompiled from 'hooks/usePrecompiled'
import queryString from 'query-string'
import {getParams} from "main/specificUtils/api";
import LinApp from "./lin/LinApp";
import moment from "moment";
import {CountdownProvider} from "./contexts/CountdownContext";

function App() {
  const [state, setState] = useContext(AppContext)
  const [userState] = useContext(UserContext)
  const {state: psState, init: initPackageSelector} = usePackageSelectorInit()
  const {init: initAuthentication} = useAuthentication()
  const {initPrecompiled, failPrecompiled} = usePrecompiled()
  // se ho qsToken e originPracticeId vince il token che mi arriva da query string
  // altrimenti faccio l'init base e controllo local e session storage
  useEffect(() => {
    if (state.qsToken && state.originPracticeId) {
      initAuthentication(state.qsToken)
    } else {
      initAuthentication()
    }
  }, [initAuthentication, state.qsToken, state.originPracticeId])

  useEffect(() => {
    const timer = setTimeout(() => {
      alert("Sessione scaduta. Ricarica la pagina per iniziare una nuova prenotazione");
      window.location.reload();
    }, 3600000); // 60 minuti

    return () => clearTimeout(timer);
  }, []);


  // init package non va fatto se ho un originPracticeId perché i dati mi precompilati li chiedo direttamente al server
  // altrimenti devo controllare se nella query string sono presenti country e webgroup
  useEffect(() => {
    if (!state.qsToken || !state.originPracticeId) {
      initPackageSelector()
    }
  }, [initPackageSelector, state.qsToken, state.originPracticeId])

  // load data from precompiled
  useEffect(() => {
    if (userState.isInitDone && userState.token && state.originPracticeId) {
      initPrecompiled()
    } else if (
      userState.isInitDone &&
      state.qsToken &&
      state.originPracticeId &&
      !userState.token
    ) {
      failPrecompiled()
    }
  }, [
    failPrecompiled,
    initPrecompiled,
    state.originPracticeId,
    state.qsToken,
    userState.isInitDone,
    userState.token,
  ])

  useEffect(() => {
    async function getProductManagementFlags() {
      const division = state.division
      const applicationType = state.applicationType
      const product = (division === '3')
        ? 11
        : (applicationType === 'hsp')
        ? 8
        : (applicationType === 'lin')
        ? 6
        : (applicationType === 'docenti')
        ? 4
        : (division === '2')
        ? 1
        : (division === '1')
        ? 3
        : null
      const confKeys = {
        "formPrenotazione.domandaVincitoreINPS": {
          status: false,
          value: null,
        },
        "formPrenotazione.inibizioneDepositiINPS": {
          status: false,
          value: null,
        },
        "formPrenotazione.disabilitaPayPalMyBank": {
          status: false,
          value: null,
        }
      }
      let results = null;
      try {
        results = await getParams(product, Object.keys(confKeys));
      } catch (error) {
        console.error('Errore nel recupero delle flag di gestione prodotto: ', error);
      }
      setState({
        ...state,
        productManagementFlags: { ...confKeys, ...(results ? results : {}) }
      })
    }
    getProductManagementFlags()
  }, [
    state.division,
    state.applicationType
  ])

  // TODO: controllare se il loader serve anche per l'inizializzazione della pratica precompilata
  if (!state.division || psState.loading || state.precompiling) {
    return <Loader />
  }

  if (state.division === '3') {
    return <AdaApp />
  }
  if (state.applicationType === 'hsp') {
    return <HspApp />
  }
  if (state.applicationType === 'docenti') {
    return <DocentiApp />
  }
  if (state.applicationType === 'lin') {
    return <LinApp />
  }
  return <MainApp />
}

export default function AppWrapper() {
  const [state, setState] = useState({
    division: '',
    formType: '',
    originPracticeId: '',
    qsToken: '',
    resortTheme: '',
  })
  useEffect(() => {
    let {
      division = '',
      type: formType = '',
      practiceid: originPracticeId = '',
      jwt: qsToken = '',
      country = '',
      webgroup = '',
      catalog = '',
    } = queryString.parse(window.location.search)
    // if (typeof window.getParameterByName === 'function') {
    // const division = window.getParameterByName('division') || ''
    // const formType = window.getParameterByName('type') || ''

    if (!formType && division === '3') {
      formType = 'ada'
    }
    if (!division) {
      window.location.search =
        'division=1' +
        (!!formType ? '&type=' + formType : '') +
        (!!originPracticeId ? '&practiceId=' + originPracticeId : '') +
        (!!qsToken ? '&jwt=' + qsToken : '') +
        (!!country ? '&country=' + country : '') +
        (!!catalog ? '&catalog=' + catalog : '') +
        (!!webgroup ? '&webgroup=' + webgroup : '')
    } else {
      setState({division, formType, originPracticeId, qsToken})
    }
    // }
  }, [])

  if (!state.division) {
    return <Loader />
  }

  const applicationType = state.formType.toLowerCase() || 'main'

  let {resortTheme = ''} = queryString.parse(window.location.search)

  return (
    <div className="App">
      <UserProvider>
        <AppProvider
          division={state.division}
          originPracticeId={state.originPracticeId}
          qsToken={state.qsToken}
          applicationType={applicationType}>
          <PackageSelectorProvider resortTheme={resortTheme}>
            <SpendingProvider>
              <PaymentProvider>
                <CountdownProvider>
                  <App />
                </CountdownProvider>
              </PaymentProvider>
            </SpendingProvider>
          </PackageSelectorProvider>
        </AppProvider>
      </UserProvider>
      <footer className="App-footer">
        <a
        href="https://giocamondo.it/club/"
        className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe" target="_blank"
        title="Regolamento Giocamondo Club">Regolamento Giocamondo Club
        </a>
        <a
          href="https://www.iubenda.com/privacy-policy/883329"
          className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
          title="Privacy Policy ">
          Privacy Policy
        </a>
        <a
          href="https://www.iubenda.com/privacy-policy/883329/cookie-policy"
          className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
          title="Cookie Policy ">
          Cookie Policy
        </a>
        <a href="#" className="iubenda-cs-preferences-link">
          Aggiorna i consensi
        </a>
      </footer>
    </div>
  )
}
