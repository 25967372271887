import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
// import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'

export const languageLevels = Object.freeze({
  A1: 'A1',
  A2: 'A2',
  B1: 'B1',
  B2: 'B2',
  C1: 'C1',
  C2: 'C2',
})

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
  },
  select: {
    width: 300,
  },
  menu: {
    width: 300,
  },
}))

export default function LanguageLevelSelector(props) {
  const classes = useStyles()
  const {value, error, handler, visible = true} = props
  if (!visible) {
    return null
  }
  return (
    <FormControl className={classes.formControl}>
      {/* <InputLabel htmlFor="age-simple">Seleziona il livello</InputLabel> */}
      <Select
        autoWidth
        error={error}
        value={value}
        name="language-lvl-selector"
        displayEmpty
        // className={classes.select}
        onChange={handler}>
        <MenuItem value="" disabled>
          <em>Seleziona il livello</em>
        </MenuItem>
        <MenuItem value={languageLevels.A1}>A1 (base)</MenuItem>
        <MenuItem value={languageLevels.A2}>A2 (elementare)</MenuItem>
        <MenuItem value={languageLevels.B1}>B1 (intermedio)</MenuItem>
        <MenuItem value={languageLevels.B2}>B2 (intermedio superiore)</MenuItem>
        <MenuItem value={languageLevels.C1}>C1 (avanzato)</MenuItem>
        <MenuItem value={languageLevels.C2}>C2 (fluente)</MenuItem>
      </Select>
    </FormControl>
  )
}

LanguageLevelSelector.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.any,
  handler: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
}
