import React, {useContext, useEffect} from 'react'
import StepPaymentWrapper from './StepPaymentWrapper'
import {AppContext} from 'contexts/AppContext'
import {PackageSelectorContext} from 'contexts/PackageSelectorContext'
import {PaymentContext} from 'contexts/PaymentContext'
import {SpendingContext} from 'contexts/SpendingContext'
import usePayment from 'hooks/usePayment'

export default function StepPayment() {
    const [state] = useContext(AppContext)
    const [psState] = useContext(PackageSelectorContext)
    const [paymentState] = useContext(PaymentContext)
    const [spendingState] = useContext(SpendingContext)
    const {handleTipo, updatePayment, confirm} = usePayment()
    const {step2Participation} = state

    const {priceToPay, priceDescription, priceError} = spendingState
    const {
        confirmed,
        paid,
        updatePaymentError,
        confirmError,
        payError,
    } = paymentState
    const hasError = !!updatePaymentError || !!confirmError || !!payError

    // scroll up on recap shown
    useEffect(() => {
        if (!!confirmed || !!paid || hasError) {
            window.scrollTo(0, 0)
        }
    }, [confirmed, paid, hasError])

    return (
        <StepPaymentWrapper
            bonusRequested={state.step2BonusRequested}
            bonusType={state.step2BonusType}
            amount={priceToPay}
            amountDescription={priceDescription}
            participation={step2Participation}
            onTipo={handleTipo}
            onUpdatePayment={updatePayment}
            onConfirm={confirm}
            paymentState={paymentState}
            priceError={priceError}
        />
    )
}
