import {useContext, useCallback, useEffect, useRef} from 'react'
import {AppContext} from 'contexts/AppContext'
import {PackageSelectorContext} from 'contexts/PackageSelectorContext'
import {SpendingContext} from 'contexts/SpendingContext'
import {UserContext} from 'contexts/UserContext'
import useApi from './useApi'
import {participationsLookup} from '../main/specificUtils/configs'

const useSpendigCalc = () => {
  const [appState] = useContext(AppContext)
  const [userState] = useContext(UserContext)
  const [psState] = useContext(PackageSelectorContext)
  const [spendingState, setSpendingState] = useContext(SpendingContext)
  const api = useApi()

  const usePrevious = (value, initialValue) => {
    const ref = useRef(initialValue);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
    const previousDeps = usePrevious(dependencies, []);
  
    const changedDeps = dependencies.reduce((accum, dependency, index) => {
      if (dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index;
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency
          }
        };
      }
  
      return accum;
    }, {});
  
    if (Object.keys(changedDeps).length) {
      console.log('[use-effect-debugger] ', changedDeps);
    }
  
    useEffect(effectHook, dependencies);
  };

  const getBasicPackagePrice = useCallback(() => {
    if (psState.package != null) {
      const choosenPackage =
        psState.packages.find(s => s.value === psState.package) || {}
      const packagePrice = choosenPackage.price
      if (spendingState.basicCoreCluster) {
        const clusterPrice = spendingState.basicCoreCluster.price
        return clusterPrice != null ? clusterPrice : packagePrice || 0
      }
      return packagePrice || 0
    } else {
      return 0
    }
  }, [psState.package, psState.packages, spendingState.basicCoreCluster])

  useEffect(() => {
    if (psState.package != null) {
      const choosenPackage = psState.packages.find(
        s => s.value === psState.package
      )
      if (
        choosenPackage &&
        userState.userData &&
        userState.userData.clusterCode
      ) {
        const cluster = userState.userData.clusterCode
        const packageCluster = choosenPackage.allPricesForMemberCluster
          ? choosenPackage.allPricesForMemberCluster[cluster]
          : null
        if (packageCluster) {
          setSpendingState(state => ({
            ...state,
            basicCoreCluster: packageCluster,
          }))
          return
        }
      }
    }
    setSpendingState(state => ({...state, basicCoreCluster: null}))
  }, [psState.package, psState.packages, setSpendingState, userState.userData])

  const getDepartureServicePrice = useCallback(() => {
    if (psState.departureService != null) {
      const city =
        psState.departureServices.find(
          item => item.value === psState.departureService
        ) || {}

      let price = city.price || 0

      if (appState.step1Participants && appState.step1Participants.length > 0) {
        price += price * appState.step1Participants.length
      }

      return price
    } else {
      return 0
    }
  }, [psState.departureService, psState.departureServices, appState])

  const getStructurePrice = useCallback(() => {
    if (psState.structure != null) {
      const structure =
        psState.structures.find(s => s.value === psState.structure) || {}
      return structure.price || 0
    } else {
      return 0
    }
  }, [psState.structure, psState.structures])

  const getProgramTypologyPrice = useCallback(() => {
    if (psState.programTypology != null) {
      const programTypology =
        psState.programTypologies.find(
          s => s.value === psState.programTypology
        ) || {}
      return programTypology.price || 0
    } else {
      return 0
    }
  }, [psState.programTypologies, psState.programTypology])

  useEffect(() => {
    async function calculateTotalPrice() {
      setSpendingState(state => ({
        ...state,
        isLoadingPrice: true,
        priceToPay: 0,
        totalPrice: 0,
        visualTotalPrice: null,
      }))
      const step1Subtotal =
        getBasicPackagePrice() +
        getDepartureServicePrice() +
        getStructurePrice() +
        getProgramTypologyPrice()
      const step2InpsServiceId = appState.step2InpsServiceId
      const step2BonusId = appState.step2BonusId
      const step4ExtraServices = appState.step4ExtraServices
      const step2VoucherCodes = appState.step2VoucherCodes
      const step1ParentFiscalCode = appState.step1ParentFiscalCode
      const step2VoucherDiscountService = appState.step2VoucherDiscountService
      const step2GiftCardDiscountService = appState.step2GiftCardDiscountService
      const step2CodicePromoDiscountService = appState.step2CodicePromoDiscountService
      const id = appState.id

      const inibireDepositiINPS = 
        appState.productManagementFlags['formPrenotazione.inibizioneDepositiINPS']
        ? (
          (appState.productManagementFlags['formPrenotazione.inibizioneDepositiINPS'].status) &&
          (appState.step2Participation === participationsLookup.INPSIEME ||
          appState.step2Participation === participationsLookup.INPSNUOVO)
        )
        : false

      if (
        step2VoucherCodes ||
        step2InpsServiceId ||
        step2BonusId ||
        (step4ExtraServices &&
          Array.isArray(step4ExtraServices) &&
          step4ExtraServices.length > 0)
      ) {
        try {
          const {
            priceToPay,
            deposit,
            visualPrice,
            totalPrice,
            helpers,
            vouchers,
          } = await api.getCurrentPrice({
            step1Subtotal,
            step2InpsServiceId,
            step2BonusId,
            step4ExtraServices,
            step2VoucherCodes,
            step1ParentFiscalCode,
            step2VoucherDiscountService,
            step2GiftCardDiscountService,
            step2CodicePromoDiscountService,
            id,
            division: appState.division,
            disabilitiesType: appState.step3DisabilitiesType,
            helperType: appState.step3DisabilitiesHelper,
            helperNumber: appState.step3DisabilitiesHelperType,
            geo:
              psState.packages &&
              psState.packages.find(p => p.value === psState.package).geo,
            basicCoreID: 
              psState.packages &&
              psState.packages.find(p => p.value === psState.package).basicCoreID,
            basicCoreClusterCode: 
              spendingState.basicCoreCluster &&
              spendingState.basicCoreCluster.code,
            step1HasStructure: !!psState.structures && psState.structures.length > 0,
            step1Structure: psState.structure,
            step1DepartureServiceIds: psState.departureService,
            step1ProgramTypology: psState.programTypology,
            packageID: psState.package
          })

          setSpendingState(state => ({
            ...state,
            isLoadingPrice: false,
            priceToPay: Number.parseInt(priceToPay, 10),
            totalPrice: totalPrice ? Number.parseInt(totalPrice, 10) : priceToPay,
            visualTotalPrice: visualPrice ? Number.parseInt(visualPrice, 10) : null,
            priceDescription:
              inibireDepositiINPS
              ? null
              : (deposit ? deposit.description : null),
            depositPrice:
              inibireDepositiINPS
              ? null
              : (deposit ? deposit.price : null),
            helperMinusValue: helpers ? helpers.minusValue : null,
            helperMinusDescription: helpers ? helpers.minusDescription : null,
            helperPlusValue: helpers ? helpers.plusValue : null,
            helperPlusDescription: helpers ? helpers.plusDescription : null,
            vouchers: vouchers
          }))
        } catch (error) {
          console.error(error)
          setSpendingState(state => ({
            ...state,
            isLoadingPrice: false,
            totalPrice: step1Subtotal,
            visualTotalPrice: null,
            priceError: true,
          }))
        }
      } else {
        setSpendingState(state => ({
          ...state,
          isLoadingPrice: false,
          totalPrice: step1Subtotal,
          visualTotalPrice: null,
        }))
      }
    }
    calculateTotalPrice()
  }, [
    api,
    appState,
    appState.division,
    appState.step2BonusId,
    appState.step2InpsServiceId,
    appState.step3DisabilitiesHelper,
    appState.step3DisabilitiesHelperType,
    appState.step3DisabilitiesType,
    appState.step4ExtraServices,
    appState.step2VoucherCodes,
    appState.step1ParentFiscalCode,
    appState.id,
    getBasicPackagePrice,
    getDepartureServicePrice,
    getProgramTypologyPrice,
    getStructurePrice,
    psState,
    setSpendingState,
  ])

  return {
    getBasicPackagePrice,
    getDepartureServicePrice,
    getProgramTypologyPrice,
    getStructurePrice,
  }
}

export default useSpendigCalc
