import {Grid} from "@material-ui/core";
import React, {useContext} from 'react';
import {AppContext} from "../../contexts/AppContext";

import {nationalities} from "../../docenti/specificUtils/configs";
import {codeToParticipation} from "../../hooks/usePayment";
import {dateToServerString} from "../../main/specificUtils/api";

export default function CustomerDataModal({state}) {
    // console.info("state", state);
    const VST = '1';
    return (
        <Grid container>
            <Grid item xs={12}>
                <h4>Dati del genitore</h4>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <small>
                            Dati anagrafici
                            <ul>
                                <li>Cognome: <b>{state.step1ParentSurname}</b></li>
                                <li>Nome: <b>{state.step1ParentName}</b></li>
                                <li>Genere: <b>{state.step1ParentGender}</b></li>
                                <li>Nazione di
                                    nascita: <b>{state.step1ParentNationality === 'Italiana' ? 'ITALIA' : state.step1ParentNationality}</b>
                                </li>
                                <li>Città di
                                    nascita: <b>{(state.step1ParentNationality === 'Italiana') ? (state.step1ParentBirthCity != null ? state.step1ParentBirthCity.name : null) : state.step1ParentForeignBirthCity}</b>
                                </li>
                                {(state.step1ParentNationality === 'Italiana') &&
                                    <li>Provincia di nascita: <b>{state.step1ParentBirthProv}</b></li>}
                                <li>Data di
                                    nascita: <b>{dateToServerString(state.step1ParentBirthDate, 'DD-MM-YYYY')}</b></li>
                                <li>Codice fiscale: <b>{state.step1ParentFiscalCode}</b></li>
                                <li>E-mail: <b>{state.step1ParentEmail}</b></li>
                                <li>Cellulare: <b>{state.step1ParentPhone}</b></li>
                            </ul>
                        </small>
                    </Grid>
                    <Grid item xs={6}>
                        <small>Residenza
                            <ul>
                                <li>Via/Piazza: <b>{state.step1ParentStreetAddress}</b></li>
                                <li>Città: <b>{state.step1ParentResidenceCity}</b></li>
                                <li>CAP: <b>{state.step1ParentCAP}</b></li>
                                <li>Provincia: <b>{state.step1ParentProv}</b></li>
                            </ul></small>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <h4>Dati del partecipante</h4>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <small>Anagrafica
                            <ul>
                                <li>Tipo di partecipazione: <b>{codeToParticipation(state.step2Participation)}</b></li>
                                <li>Cognome: <b>{state.step1ChildSurname}</b></li>
                                <li>Nome: <b>{state.step1ChildName}</b></li>
                                <li>Genere: <b>{state.step1ChildGender}</b></li>
                                <li>Nazione di
                                    nascita: <b>{state.step1ChildNationality === 'Italiana' ? 'ITALIA' : state.step1ChildBirthNation}</b>
                                </li>
                                <li>Città di
                                    nascita: <b>{(state.step1ChildNationality === 'Italiana') ? (state.step1ChildBirthCity != null ? state.step1ChildBirthCity.name : null) : state.step1ChildForeignBirthCity}</b>
                                </li>
                                {(state.step1ChildNationality === 'Italiana') &&
                                    <li>Provincia di nascita: <b>{state.step1ChildBirthProv}</b></li>}
                                <li>Data di
                                    nascita: <b>{dateToServerString(state.step1ChildBirthDate, 'DD-MM-YYYY')}</b></li>
                                <li>Codice fiscale: <b>{state.step1ChildFiscalCode}</b></li>
                                <li>E-mail: <b>{state.step1ChildEmail}</b></li>
                                <li>Cellulare: <b>{state.step1ChildCellPhone}</b></li>
                            </ul></small>
                    </Grid>
                    <Grid item xs={6}>
                        <small>Residenza
                            <ul>
                                <li>Residente con il genitore: <b>{state.step1SameResidence ? "Sì" : "No"}</b></li>
                                <li>Via/Piazza: <b>{state.step1ChildStreetAddress}</b></li>
                                <li>Città: <b>{state.step1ChildResidenceCity}</b></li>
                                <li>CAP: <b>{state.step1ChildCAP}</b></li>
                                <li>Provincia: <b>{state.step1ChildProv}</b></li>
                            </ul></small>
                    </Grid>
                </Grid>
            </Grid>
            {(state.division === VST) && (state.flDocInserted !== '1') && <Grid item xs={12}>
                <h4>Documento</h4>
                <small>
                    <ul>
                        <li>Vuoi fornirci sin da subito i dati del documento del Partecipante alla vacanza studio?: <b>No,
                            li comunicherò successivamente</b>
                        </li>
                    </ul>
                </small>
            </Grid>}
            {(state.division === VST) && (state.flDocInserted === '1') && <Grid item xs={12}>
                <h4>Documento</h4>
                <small>
                    <ul>
                        <li>Tipo
                            documento: <b>{state.step1ChildDocType ? (state.step1ChildDocType === 'idCard' ? "Carta d'identità" : "Passaporto") : ""}</b>
                        </li>
                        <li>Numero documento: <b>{state.step1ChildDocNumber}</b></li>
                        <li>Nazionalità riportata sul documento: <b>{state.step1ChildDocCountry}</b></li>
                        <li>Data emissione: <b>{dateToServerString(state.step1ChildDocEmission, 'DD-MM-YYYY')}</b></li>
                        <li>Data scadenza: <b>{dateToServerString(state.step1ChildDocExpiration, 'DD-MM-YYYY')}</b></li>
                    </ul>
                </small>
            </Grid>}
        </Grid>
    );
}
