import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles(theme => ({
  textField8: {
    width: '62.666667%',
    marginLeft: 0,
    marginRight: 0,
  },
}))

export default function Intolerance(props) {
  const classes = useStyles()
  const {
    // handlers
    onChange,
    onFlagChange,
    // step3 state
    step3Intolerance,
    step3IntoleranceText,
    step3IntoleranceType,
    // step3MedicalCert,
  } = props
  return (
    <Grid container className="box-light">
      <Grid item sm={4} xs={12}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">
            Desideri segnalare allergie e/o intolleranze del partecipante?
          </FormLabel>
          <RadioGroup
            name="disable"
            className={classes.group}
            value={step3Intolerance}
            onChange={onFlagChange('step3Intolerance')}>
            <FormControlLabel value="si" control={<Radio />} label="Sì" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>

      {step3Intolerance === 'si' && (
        <Grid item sm={8} xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              Specifica la tipologia dell'allergia e/o intolleranza
            </FormLabel>
            <TextField
              id="type-intollerances"
              select
              className={classes.numbersSelect}
              value={step3IntoleranceType}
              onChange={onChange('step3IntoleranceType')}
              // inputProps={{style: {textAlign: 'right'}}}
              SelectProps={{
                MenuProps: {
                  className: classes.intolleranceMenu,
                },
              }}
              margin="normal">
              {/*<MenuItem value={'allergia-alimentare'}>
                Allergia Alimentare
              </MenuItem>
              <MenuItem value={'intolleranza-alimentare'}>
                Intolleranza Alimentare
              </MenuItem>*/}
              <MenuItem value={'celiachia'}>
                Celiachia
              </MenuItem>
              <MenuItem value={'altra-allergia-intolleranza'}>
                Altra tipologia di Esigenza
              </MenuItem>
            </TextField>
          </FormControl>

          <br />
          <FormLabel component="legend">
            Specifica la necessità che intendi comunicare
          </FormLabel>
          <TextField
            id="multiline-flexible"
            multiline
            rowsMax="3"
            value={step3IntoleranceText}
            className={classes.textField8}
            margin="normal"
            onChange={onChange('step3IntoleranceText')}
          />

          {/* <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              Si è in possesso di una certificazioni medica per questa allergia
              e/o intolleranza?
            </FormLabel>
            <RadioGroup
              name="disable"
              className={classes.group}
              value={step3MedicalCert}
              onChange={onChange('step3MedicalCert')}>
              <FormControlLabel value="si" control={<Radio />} label="Sì" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl> */}
        </Grid>
      )}
    </Grid>
  )
}
//TODO:
//Salvataggio dati step3IntoleranceType

Intolerance.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFlagChange: PropTypes.func.isRequired,
  // errors: PropTypes.object,
  step3Intolerance: PropTypes.oneOf(['si', 'no', '']),
  step3IntoleranceText: PropTypes.string,
  // step3MedicalCert: PropTypes.oneOf(['si', 'no', '']),
  step3IntoleranceType: PropTypes.string,
}
