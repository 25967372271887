import React, {useContext, useState} from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {Button} from '@material-ui/core'
import {getAlternativeServices, verifyVoucher} from 'lin/specificUtils/api'
import moment from 'moment'
import {AppContext} from 'contexts/AppContext'
import {PackageSelectorContext} from 'contexts/PackageSelectorContext'

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textField4: {
    width: '32.333333%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField6: {
    width: '46.666%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  menu: {
    width: 200,
  },
}))

export default function Voucher(props) {
  const [appState, setAppState] = useContext(AppContext)
  const [psState] = useContext(PackageSelectorContext)
  const classes = useStyles()
  const {values, onChange} = props
  const [voucherCodes, setVoucherCodes] = useState(values ? values.split(',') : [])
  const [voucherCodeNew, setVoucherCodeNew] = useState('')
  const [resultState, setResultState] = useState('')

  const handleVerification = async () => {
    setResultState('')

    const newVoucherCodes = [...voucherCodes, voucherCodeNew]
    const newVoucherCodesAsString = newVoucherCodes.join(',')

    const servicesVoucherDiscount = await getAlternativeServices(
      psState.package,
      'scontovoucher',
      parseInt(appState.division)
    )

    const servicesGiftCardDiscount = await getAlternativeServices(
      psState.package,
      'scontogiftcard',
      parseInt(appState.division)
    )

    const servicesCodicePromoDiscount = await getAlternativeServices(
      psState.package,
      'scontocodicepromo',
      parseInt(appState.division)
    )

    if (servicesVoucherDiscount.length > 0) {
      setAppState(prevState => {
        return {
          ...prevState,
          step2VoucherDiscountService: servicesVoucherDiscount[0].value
        }
      });
    }

    if (servicesGiftCardDiscount.length > 0) {
      setAppState(prevState => {
        return {
          ...prevState,
          step2GiftCardDiscountService: servicesGiftCardDiscount[0].value
        }
      });
    }

    if (servicesCodicePromoDiscount.length > 0) {
      setAppState(prevState => {
        return {
          ...prevState,
          step2CodicePromoDiscountService: servicesCodicePromoDiscount[0].value
        }
      });
    }

    const res = await verifyVoucher(newVoucherCodesAsString, appState.step1ParentFiscalCode, appState.division)

    if (res.success === false) {
      let error = 'error'
      if (res.generalErrors) {
        if (res.generalErrors.dupVoucherCodes) {
          error = 'dup'
        }
      }
      return setResultState(error)
    }

    setVoucherCodes(newVoucherCodes)

    setVoucherCodeNew('')

    setResultState('success')

    onChange('step2VoucherCodes')({target: {value: newVoucherCodesAsString}})
  }

  const handleResetAtPos = (pos) => {
    setResultState('')

    const newVoucherCodes = voucherCodes.filter((currentValue, index) => index !== pos)

    setVoucherCodes(newVoucherCodes)

    onChange('step2VoucherCodes')({target: {value: newVoucherCodes.join(',')}})
  }

  return (
    <div className="box-light boxed voucher-box">
      <Typography variant="h4">Hai codici Voucher/Gift Card/Promozioni da utilizzare?</Typography>
      <Typography variant="body1">
        Puoi inserire fino a tre codici voucher/gift card/promozioni.
        L'importo corrispondente al valore del voucher/gift card/promozione verrà scalato dal saldo.
      </Typography>

      { voucherCodes.map((value, index) => (
        <span>
          <TextField
          id={"codice-voucher-" + (index+1)}
          //required
          label={"Codice " + (index+1)}
          className={clsx(classes.textField6, 'select', 'col-xs-12')}
          value={value}
          //onChange={e => setVoucherCodeNew(e.target.value)}
          margin="normal"
          disabled={true}
          />

          <Button
          className="v-margin-top"
          variant="outlined"
          color="secondary"
          onClick={e => handleResetAtPos(index)}>
            Rimuovi
          </Button>
        </span>
      )) }

      { (voucherCodes.length < 3) && (
        <span>
        <TextField
        id="codice-voucher-add"
        //required
        label="Aggiungi Codice"
        className={clsx(classes.textField6, 'select', 'col-xs-12')}
        value={voucherCodeNew}
        onChange={e => setVoucherCodeNew(e.target.value)}
        margin="normal"
        />

        <Button
        className="v-margin-top"
        //disabled={!!value}
        variant="contained"
        color="primary"
        onClick={handleVerification}>
        Applica
        </Button>
        </span>
      )}

      <br />

      <div className="voucher-message">
      {resultState === 'success' && 'Voucher valido.'}
      {resultState === 'dup' && 'Voucher duplicato!'}
      {resultState === 'error' && 'Attenzione! Controlli di aver inserito sia il codice voucher che, nello step precedente, il codice fiscale del genitore corretti. NB: il codice fiscale deve essere del genitore che ha effettuato prenotazioni con Giocamondo negli anni precedenti.'}
      </div>
    </div>
  )
}

Voucher.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}
