import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/InfoOutlined'

const useStyles = makeStyles({
  icon: {
    // fontSize: '0.75rem',
  },
  wrapper: {
    height: 18,
    width: 18,
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

function MemberdshipInfoIcon({arrowColor, backgroundColor}) {
  const classes = useStyles()

  return (
    <span className={classes.wrapper} style={{backgroundColor}}>
      <InfoIcon className={classes.icon} style={{color: arrowColor}} />
    </span>
  )
}

MemberdshipInfoIcon.defaultProps = {
  arrowColor: '#FFFFFF',
  backgroundColor: '#00B219',
}

MemberdshipInfoIcon.propTypes = {
  arrowColor: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default MemberdshipInfoIcon
