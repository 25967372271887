import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SquareBox from 'components/SquareBox'

const useStyles = makeStyles(theme => ({
  text: {
    fontStyle: 'italic',
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  verticalSpacer: {
    height: theme.spacing(1),
  },
  wrapper: {
    padding: theme.spacing(2),
  },
}))

export default function MembershipLoginSuccessMessage({name}) {
  const classes = useStyles()

  return (
    <SquareBox backgroundColor="#A0E8BD" borderColor="#00B219" borderWidth={1}>
      <div className={`${classes.text} ${classes.wrapper}`}>
        <Typography>
          Ciao <span className={classes.textBold}>{name}</span>,
          <br />
          <span className={classes.textBold}>GiocamondoClub</span> ti dà il
          benvenuto.
        </Typography>
        <div className={classes.verticalSpacer} />
        <Typography variant="body2">
          Puoi procedere alla prenotazione del tuo pacchetto!
        </Typography>
      </div>
    </SquareBox>
  )
}
