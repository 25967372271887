import React from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'

function Autocomplete(props) {
  const {
    cache = false,
    value,
    placeholder = 'Seleziona un elemento',
    getOptions,
    onDelete,
    onSelect,
    disabled = false,
  } = props
  return (
    <AsyncSelect
      defaultOptions
      disabled={disabled}
      cacheOptions={cache}
      value={value}
      onChange={item => {
        if (item == null) {
          onDelete()
        } else {
          onSelect(item)
        }
      }}
      placeholder={placeholder}
      noOptionsMessage={() =>
        'Nessun risultato trovato o caratteri insufficienti'
      }
      loadingMessage={() => 'Ricerca...'}
      filterOptions={options => options}
      loadOptions={async input => {
        if (
          input &&
          (input.length > 2 ||
            (input.length === 2 &&
              ['LU', 'NE', 'RE', 'RO', 'VO'].includes(input.toUpperCase())))
        ) {
          return getOptions(input)
        } else {
          return []
        }
      }}
    />
  )
}

Autocomplete.propTypes = {
  cache: PropTypes.bool,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  getOptions: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default Autocomplete
