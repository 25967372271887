import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import DateComponent from './DateComponent'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

function BirthPlaceForeignInput(props) {
  const {
    birthNation,
    birthNationError,
    handleBirthNationChange,
    foreignBirthCity,
    foreignBirthCityError,
    handleForeignBirthCityChange,
    birthDate,
    birthDateError,
    handleBirthDateChange,
  } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="Nazione *"
          className="align-self-end"
          value={birthNation}
          error={birthNationError}
          onChange={evt => handleBirthNationChange(evt.target.value)}
          margin="normal"
          helperText={!!birthNationError && 'richiesto *'}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="Città di nascita *"
          className="align-self-end"
          value={foreignBirthCity}
          error={!!foreignBirthCityError}
          onChange={evt => handleForeignBirthCityChange(evt.target.value)}
          helperText={!!foreignBirthCityError && 'richiesto *'}
          margin="normal"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl>
          <DateComponent
            onChange={handleBirthDateChange}
            value={birthDate}
            error={birthDateError}
          />
          <FormHelperText>
            Scrivi la data come da esempio 27/01/1993
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  )
}

BirthPlaceForeignInput.propTypes = {
  birthNation: PropTypes.string,
  birthNationError: PropTypes.any,
  handleBirthNationChange: PropTypes.func.isRequired,
  foreignBirthCity: PropTypes.string,
  foreignBirthCityError: PropTypes.any,
  handleForeignBirthCityChange: PropTypes.func.isRequired,
  birthDate: PropTypes.instanceOf(Date),
  birthDateError: PropTypes.any,
  handleBirthDateChange: PropTypes.func.isRequired,
}

export default BirthPlaceForeignInput
