import React from 'react'
import StepperWrapper from './components/StepperWrapper'
import {DialogContextProvider} from 'contexts/DialogContext'

export default function DocentiApp() {
    return (
        <DialogContextProvider>
            <StepperWrapper/>
        </DialogContextProvider>
    )
}
