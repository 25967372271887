import {useContext, useEffect} from 'react'
import {AppContext} from '../contexts/AppContext'
import {PackageSelectorContext} from '../contexts/PackageSelectorContext'
import {PaymentContext} from '../contexts/PaymentContext'
import useApi from './useApi'
import {participationsLookup} from '../main/specificUtils/configs'

export default function useExtraServices() {
  const [state, setState] = useContext(AppContext)
  const [psState] = useContext(PackageSelectorContext)
  const api = useApi()
  const [paymentState, setPaymentState] = useContext(PaymentContext)

  function onSelectExtraService(id) {
    const {
      extraServices: allExtraServices,
      step4ExtraServices: selectedExtraServices
    } = state
    const isAlreadySelected = selectedExtraServices.includes(id)
    let newExtraServices = [...selectedExtraServices]
    if (isAlreadySelected) {
      //remove
      newExtraServices = newExtraServices.filter(f => f !== id)
    } else {
      //add new
      // per prima cosa rimuovo eventuali extra servizi del gruppo associato
      allExtraServices.forEach(extrabundle => {
        //per ogni gruppo di servizi extra
        extrabundle.extraservices.forEach(extraservice => {
          //per ogni servizio nella terzina del gruppo
          if (extraservice.value === id) {
            extrabundle.extraservices.forEach(removeElement => {
              newExtraServices = newExtraServices.filter(
                f => f !== removeElement.value
              )
            })
          }
        })
      })
      // alla fine riaggiungo l'extra servizio selezionato
      newExtraServices = newExtraServices.concat([id])
    }
    setState(state => ({...state, step4ExtraServices: newExtraServices}))
  }

  function onDeselectOptionalExtraServices(ids) {
      const {
          extraServices: allExtraServices,
          step4ExtraServices: selectedExtraServices
      } = state
      let newExtraServices = [...selectedExtraServices]
      ids.forEach(id => {
          const isAlreadySelected = selectedExtraServices.includes(id)
          if (isAlreadySelected) {
              //remove
              newExtraServices = newExtraServices.filter(f => f !== id)
          } else {
              //add new
              // per prima cosa rimuovo eventuali extra servizi del gruppo associato
              allExtraServices.forEach(extrabundle => {
                  //per ogni gruppo di servizi extra
                  extrabundle.extraservices.forEach(extraservice => {
                      //per ogni servizio nella terzina del gruppo
                      if (extraservice.value === id) {
                          extrabundle.extraservices.forEach(removeElement => {
                              newExtraServices = newExtraServices.filter(
                                  f => f !== removeElement.value
                              )
                          })
                      }
                  })
              })
              // alla fine riaggiungo l'extra servizio selezionato
              newExtraServices = newExtraServices.concat([id])
          }
      });
      setState(state => ({...state, step4ExtraServices: newExtraServices}))
  }

  useEffect(() => {
    const getAllExtraServices = async () => {
      async function getExtraServiceByAlternative(alternative) {
        const {division} = state
        const packageId = psState.package
        try {
          const data =
            (await api.getExtraServices(packageId, alternative, division)) || {}
          if (
            data[alternative] &&
            data[alternative].extraservices &&
            data[alternative].extraservices.length > 0
          ) {
            return {...data[alternative], alternative} || null
          }
        } catch (error) {
          console.error(
            `Error getting extra service for alternative ${alternative}`,
            error
          )
        }
        return null
      }
      const {step2Participation} = state
      setState(state => ({...state, fetchingExtraServices: true}))
      let alternatives = new Array(10)
        .fill(0)
        .map((p, index) => `Extra${index + 1}`)
      const participationNames = {
        [participationsLookup.INPSIEME]: 'INPS',
        [participationsLookup.INPSNUOVO]: 'INPSN',
        [participationsLookup.PRIVATO]: 'PVT',
        [participationsLookup.AZIENDA_CONVENZIONATA]: 'AZ'
      }
      alternatives = alternatives.concat(alternatives.map(v => `${v}:${participationNames[step2Participation]}`))
      alternatives.push(`PAGAMENTO-EXTRA:${participationNames[step2Participation]}`)

      let newExtraServices = []
      for (let alternative of alternatives) {
        const extraService = await getExtraServiceByAlternative(alternative)
        newExtraServices = newExtraServices.concat(extraService || [])
      }

      const hasExtraPaymentServices = newExtraServices.some(({alternative}) => alternative.includes('PAGAMENTO-EXTRA:'))
      setPaymentState(state => ({...state, hasExtraPaymentServices}))

      const filteredAllExtraServices = [...newExtraServices.filter(es => es.extraservices.filter(x => x.visibilityForm).length > 0).flat()];

      setState(state => ({
        ...state,
        extraServices: filteredAllExtraServices,
        fetchingExtraServices: false,
      }))
    }
    // console.log('called')
    getAllExtraServices()
  }, [api, psState.package, setState, setPaymentState, state.division])

  function clearExtraServices() {
    setState(state => ({...state, extraServices: []}))
  }

  return {
    onSelectExtraService,
    onDeselectOptionalExtraServices,
    // getAllExtraServices,
    clearExtraServices,
  }
}
