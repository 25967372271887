import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import PackageSelectorComponent from './PackageSelector'
// import {PackageSelectorContext} from '../../contexts/PackageSelectorContext'
import usePackageSelectorContext from 'hooks/usePackageSelectorContext'
import {UserContext} from 'contexts/UserContext'
import {AppContext} from 'contexts/AppContext'

export default function PackageSelector(props) {
  const [userState] = useContext(UserContext)
  const [appState] = useContext(AppContext)

  const {enableStructures, enableProgramTypologies} = props
  const {
    state,
    chooseCatalog,
    chooseNation,
    chooseResortTheme,
    chooseWebGroup,
    choosePackage,
    chooseDepartureService,
    chooseStructure,
    chooseProgramTypology,
  } = usePackageSelectorContext(enableStructures, enableProgramTypologies)
  const {clusterCode} = userState.userData || {}
  const {
    webGroup,
    packages,
    loadingPackages,
    package: packageId,
    loadingDepartureServices,
    departureServices,
    loadingProgramTypologies,
    programTypologies,
  } = state

  const noPackagesAvailable =
    !!webGroup && !loadingPackages && packages.length === 0

  const noDepartureServicesAvailable =
    !loadingPackages &&
    !!packageId &&
    !loadingDepartureServices &&
    departureServices.length === 0

  const noProgramTypologiesAvailable =
    enableProgramTypologies &&
    !loadingPackages &&
    !!packageId &&
    !loadingProgramTypologies &&
    programTypologies.length === 0

  return (
    <PackageSelectorComponent
      {...props}
      clusterCode={clusterCode}
      state={state}
      noPackagesAvailable={noPackagesAvailable}
      noDepartureServicesAvailable={noDepartureServicesAvailable}
      noProgramTypologiesAvailable={noProgramTypologiesAvailable}
      onCatalogChange={chooseCatalog}
      onNationChange={chooseNation}
      onResortThemeChange={chooseResortTheme}
      onWebGroupChange={chooseWebGroup}
      onPackageChange={choosePackage}
      onDepartureServiceChange={chooseDepartureService}
      onStructureChange={chooseStructure}
      onProgramTypologyChange={chooseProgramTypology}
      applicationType={appState.applicationType}
    />
  )
}

PackageSelector.propTypes = {
  title: PropTypes.string,
  groupsTitle: PropTypes.string,
  turnsTitle: PropTypes.string,
  noTurnsText: PropTypes.string,
  departuresTitle: PropTypes.string,
  noDeparturesText: PropTypes.string,
  structuresTitle: PropTypes.string,
  programTypologiesTitle: PropTypes.string,
  noProgramTypologiesText: PropTypes.string,
  enableStructures: PropTypes.bool,
  enableProgramTypologies: PropTypes.bool,
  enableTurnPrice: PropTypes.bool,
}
