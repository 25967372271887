import * as actions from './UserActions'

export const initialState = Object.freeze({
  token: null,
  isInitDone: false,
  isInitDoing: false,
  signType: null,
  isFetching: false,
  error: null,
  userData: null,
})

export function reducer(state, action) {
  switch (action.type) {
    case actions.INIT_START: {
      return {
        ...initialState,
        isInitDoing: true,
      }
    }
    case actions.INIT_COMPLETED: {
      const {token = null, userData = null} = action
      return {
        ...state,
        isInitDoing: false,
        isInitDone: true,
        token,
        userData,
      }
    }
    case actions.SIGN_REQUEST: {
      const {signType, userData = null} = action
      return {
        ...state,
        isFetching: true,
        error: false,
        userData,
        signType,
        token: null,
      }
    }
    case actions.SIGN_SUCCESS: {
      const {token, userData} = action
      return {
        ...state,
        isFetching: false,
        token,
        userData,
      }
    }
    case actions.SIGN_ERROR: {
      const {error} = action
      return {
        ...state,
        isFetching: false,
        error,
      }
    }
    case actions.SIGN_OUT: {
      return {
        ...initialState,
        isInitDone: true,
      }
    }
    case actions.RESET_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    default:
      console.log({action: action.type})
      throw new Error('Unexpected action')
  }
}
