import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ExtraServices from '../../../components/ExtraServices'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {Alert, AlertTitle} from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textField12: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  textField8: {
    width: '62.666667%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField6: {
    width: '46.666%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField4: {
    width: '32.333333%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField2: {
    width: '16.666667%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
}))

export default function StepExtraServices(props) {
  const classes = useStyles()
  const {
    division,
    step4Privacy,
    step4Marketing,
    step4Profilation,
    step4Profilation2,
    onChangeValue,
  } = props
  const errors = props.errors || {}
  const [showPrivacy, setShowPrivacy] = useState(false)
  const [showMarketing, setShowMarketing] = useState(false)

  const handleValueChange = label => event => {
    const {value} = event.target
    onChangeValue(label, value)
  }

  const handleMarketingChange = event => {
    const {value} = event.target
    onChangeValue('step4Marketing', value)
    setShowMarketing(true)
  }

  const handlePrivacyChange = event => {
    const {checked} = event.target
    onChangeValue('step4Privacy', checked)
    setShowPrivacy(true)
  }

  return (
    <React.Fragment>
      <Grid container className={classes.root + ' step-4'} spacing={2}>
        <Grid container spacing={2} className="extra-services-area v-divider">
          <ExtraServices />
        </Grid>

        <Grid container spacing={2} className="box-pp v-divider">
        <Grid item xs={12} className="bordered-bottom pp-s4">
          {division === '1' ? (
            <h3 className="textlight">
              PER CLIENTI INPS:
              <strong>
                <u>MI IMPEGNO ad effettuare la prenotazione SOLO con Giocamondo la quale garantirà la disponibilità per il partecipante nel soggiorno opzionato 2024.</u>
              </strong>
              Ai fini dell’organizzazione del nostro lavoro, ti chiediamo di evitare di effettuare ulteriori iscrizioni con altre agenzie/tour operator poiché, ogni iscrizione, comporta la garanzia della disponibilità del posto (sia nel college che nell’eventuale viaggio aereo a/r).
            </h3>
          ) : (
            <h3 className="textlight">
              PER CLIENTI INPS:
              <strong>
                <u>MI IMPEGNO ad effettuare la prenotazione SOLO con Giocamondo la quale garantirà la disponibilità per il partecipante nel soggiorno opzionato 2024.</u>
              </strong>
                Ai fini dell’organizzazione del nostro lavoro, ti chiediamo di evitare di effettuare ulteriori iscrizioni con altre agenzie/tour operator poiché, ogni iscrizione, comporta la garanzia della disponibilità del posto (sia nell’hotel che nell’eventuale viaggio aereo, bus e/o treno a/r).
            </h3>
          )}

          <br />
        </Grid>
        {Boolean(!step4Privacy || showPrivacy) && (
          <Grid item xs={12} className="bordered-bottom pp-s4">
            <p>
              <small>
                Informativa estesa sulla
                {division === '1' ? (
                  <a
                    href="https://www.iubenda.com/privacy-policy/656885"
                    title="Informativa privacy Giocamondo Study"
                    rel="noopener noreferrer"
                    target="_blank">
                    {' '}
                    privacy
                  </a>
                ) : (
                  <a
                    href="https://www.iubenda.com/privacy-policy/411930"
                    title="Informativa privacy La Mia Estate"
                    rel="noopener noreferrer"
                    target="_blank">
                    {' '}
                    privacy
                  </a>
                )}
                . Ai sensi e per gli effetti dell'art.4 DPR 445/2000, in relazione al rilascio di dichiarazioni mendaci, dichiaro che le informazioni rese nel presente form corrispondono a verità e accetto che queste vengano utilizzate unicamente per le finalità per le quali sono state acquisite (GDPR - Regolamento 2016/679). Per quanto riguarda i consensi da lei sottoscritti le comunichiamo che limiteremo le nostre attività sui vostri dati, per le finalità indicate nell’informativa, entro i limiti delle autorizzazioni che ci avete concesso e al fine di verificare i consensi sottoscritti può inviare una mail al seguente indirizzo:
                {division === '1' ? (
                  <a
                    href="mailto:privacy@giocamondostudy.it"
                    title="Mail per verificare i consensi sottoscritti">
                    privacy@giocamondostudy.it
                  </a>
                ) : (
                  <a
                    href="mailto:privacy@giocamondo.it"
                    title="Mail per verificare i consensi sottoscritti">
                    privacy@giocamondo.it
                  </a>
                )}
                . L'accettazione è obbligatoria per poter procedere all'invio
                dei dati.
              </small>
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={step4Privacy}
                    onChange={handlePrivacyChange}
                  />
                }
                label="Dichiaro di aver preso visione della privacy policy"
              />
              {Boolean(errors && errors.step4Privacy) && (
                <span className="text-warning">
                  L'accettazione è obbligatoria
                </span>
              )}
            </p>
          </Grid>
        )}

        {Boolean(!step4Marketing || showMarketing) && (
          <Grid item xs={12} className="bordered-bottom mk-s4">
            <p>
              <small>
                Consenti il trattamento dei Tuoi dati personali per il
                ricevimento della Newsletter aziendale, di comunicazioni
                relative a nostri servizi, prodotti, promozioni per finalità di
                marketing diretto?
                {division === '1' ? (
                  <a
                    href="https://www.iubenda.com/privacy-policy/656885"
                    title="Informativa privacy Giocamondo Study"
                    rel="noopener noreferrer"
                    target="_blank">
                    Leggi qui
                  </a>
                ) : (
                  <a
                    href="https://www.iubenda.com/privacy-policy/411930"
                    title="Informativa privacy La Mia Estate"
                    rel="noopener noreferrer"
                    target="_blank">
                    Leggi qui
                  </a>
                )}
                l'informativa estesa sulla privacy per maggiori dettagli.
              </small>
            </p>
            <RadioGroup
              aria-label="position"
              name="position"
              value={step4Marketing}
              onChange={handleMarketingChange}
              row>
              <FormControlLabel
                value="si"
                control={<Radio color="primary" />}
                label="Acconsento"
                labelPlacement="end"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="Non Acconsento"
                labelPlacement="end"
              />
            </RadioGroup>
          </Grid>
        )}
        <Grid item xs={12} className="bordered-bottom nl-s4">
          <p>
            <small>
              Consenti il trattamento dei Tuoi dati personali per permetterci di
              fornire servizi, prodotti e promozioni personalizzati (finalità di
              profilazione)?
              {division === '1' ? (
                <a
                  href="https://www.iubenda.com/privacy-policy/656885"
                  title="Informativa privacy Giocamondo Study"
                  rel="noopener noreferrer"
                  target="_blank">
                  {' '}
                  Leggi qui{' '}
                </a>
              ) : (
                <a
                  href="https://www.iubenda.com/privacy-policy/411930"
                  title="Informativa privacy La Mia Estate"
                  rel="noopener noreferrer"
                  target="_blank">
                  {' '}
                  Leggi qui{' '}
                </a>
              )}
              l'informativa estesa sulla privacy per maggiori dettagli.
            </small>
          </p>
          <RadioGroup
            aria-label="position"
            name="position"
            value={step4Profilation2}
            onChange={handleValueChange('step4Profilation2')}
            row>
            <FormControlLabel
              value="si"
              control={<Radio color="primary" />}
              label="Acconsento"
              labelPlacement="end"
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" />}
              label="Non Acconsento"
              labelPlacement="end"
            />
          </RadioGroup>
        </Grid>

        <Grid item xs={12} className="bordered-bottom profilation-s4">
          <p>
            <small>
              Consenti il trattamento dei dati personali e particolari, come
              quelli previsti dagli articoli 9 e 10 del GDPR “categorie
              particolari di dati personali” (es. stato di salute) Suoi o dei
              Suoi familiari, anche minori, da Lei inseriti ai fini
              dell’esecuzione delle misure precontrattuali così come indicato
              nell’informativa. Nel momento in cui inserisce dati di questa
              tipologia essi diventano estremamente rilevanti ai fini dello
              svolgimento del servizio da noi fornito.
              {division === '1' ? (
                <a
                  href="https://www.iubenda.com/privacy-policy/656885"
                  title="Informativa privacy Giocamondo Study"
                  rel="noopener noreferrer"
                  target="_blank">
                  {' '}
                  Leggi qui{' '}
                </a>
              ) : (
                <a
                  href="https://www.iubenda.com/privacy-policy/411930"
                  title="Informativa privacy La Mia Estate"
                  rel="noopener noreferrer"
                  target="_blank">
                  {' '}
                  Leggi qui{' '}
                </a>
              )}
              l'informativa estesa sulla privacy per maggiori dettagli.
            </small>
          </p>
          <RadioGroup
            aria-label="position"
            name="position"
            value={step4Profilation}
            onChange={handleValueChange('step4Profilation')}
            row>
            <FormControlLabel
              value="si"
              control={<Radio color="primary" />}
              label="Acconsento"
              labelPlacement="end"
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" />}
              label="Non Acconsento"
              labelPlacement="end"
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </Grid>
    <br/>

    <div className="big-text">
      <Alert severity="warning">
        <AlertTitle>Sei quasi arrivato alla fine!</AlertTitle>
        Nel prossimo step potrai concludere la tua prenotazione.
        {/* <strong>Verifica sul riepilogo prenotazione che i dati inseriti siano corretti.</strong> */}
        <br/><strong>Cliccando su "Vai alla conferma" non potrai più modificare la tua prenotazione.</strong>
      </Alert>
    </div>
    </React.Fragment>
  )
}

StepExtraServices.propTypes = {
  division: PropTypes.string.isRequired,
  step4Privacy: PropTypes.bool,
  step4Marketing: PropTypes.oneOf(['si', 'no', '']),
  step4Profilation: PropTypes.oneOf(['si', 'no', '']),
  step4Profilation2: PropTypes.oneOf(['si', 'no', '']),
  errors: PropTypes.object,
  onChangeValue: PropTypes.func.isRequired,
}
