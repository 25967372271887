import React from 'react'
// import Typography from '@material-ui/core/Typography'
import SidebarWrapper from '../SidebarWrapper'

export default function Recap(props) {
  // const {division} = props
  return (
    <div className="content-summary">
      <div className="text-center msg-confirm">
        {/* <Typography variant="h4">
          Grazie per aver scelto
          {division === '1'
            ? ' una vacanza studio GIOCAMONDO STUDY! '
            : ' un soggiorno LA MIA ESTATE!'}
        </Typography> */}
        <div className="container-msg">
          {/* <p>
            Controlli l’allegato alla mail che ha appena ricevuto e verifichi
            che le informazioni inserite siano corrette.
            <br />
            In caso contrario ci contatti telefonicamente allo 0736343440 oppure
            allo 0736336339
          </p> */}
          <p>Di seguito il riepilogo della sua prenotazione</p>
        </div>
        <SidebarWrapper />
      </div>
    </div>
  )
}
