import {useContext, useCallback} from 'react'
import useApi from './useApi'
import {PackageSelectorContext} from '../contexts/PackageSelectorContext'
import {AppContext} from '../contexts/AppContext'
import {UserContext} from '../contexts/UserContext'
import * as psActions from '../contexts/PackageSelectorActions'
import queryString from 'query-string'

function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}

export default function usePrecompiled() {
  const [userState] = useContext(UserContext)
  const [state, setState] = useContext(AppContext)
  const [, psDispatch] = useContext(PackageSelectorContext)
  const api = useApi()

  const {isInitDone, token} = userState

  const failPrecompiled = useCallback(async () => {
    setState(state => ({
      ...state,
      precompiling: false,
    }))

    const {
      division = '',
      type: formType = '',
      country = '',
      webgroup = '',
      catalog = '',
    } = queryString.parse(window.location.search)
    window.location.search =
      'division=' +
      division +
      (!!formType ? '&type=' + formType : '') +
      (!!country ? '&country=' + country : '') +
      (!!catalog ? '&catalog=' + catalog : '') +
      (!!webgroup ? '&webgroup=' + webgroup : '')
  }, [setState])

  const initPrecompiled = useCallback(async () => {
    if (!isInitDone || !token) {
      return null
    }
    // if (!state.precompiling) {
    //   setState(state => ({...state, precompiling: true}))
    // }
    try {
      const response = await api.getPurchaseIntentFromPracticeId(
        token,
        state.originPracticeId
      )
      // console.log(response)
      const {
        purchaseIntent = {},
        catalogs = [],
        destinations = [],
        packages = [],
        turns = [],
        cities = [],
        structures = [],
        programTypologies = [],
        bonuses = [],
        contributiINPS = [],
      } = response

      let step1ChildBirthCity = null
      let step1ChildBirthProv = purchaseIntent.step1ParentBirthProv
      if (purchaseIntent.step1ChildBirthCity) {
        const birthPlaceComuni = await api.getComuniExactByName(
          purchaseIntent.step1ChildBirthCity
        )
        if (birthPlaceComuni.length > 0) {
          step1ChildBirthCity = birthPlaceComuni[0]
          step1ChildBirthProv = birthPlaceComuni[0].car
        }
      }

      let step1ChildResidenceCityObj = null
      let step1ChildResidenceCity = ''
      let step1ChildProv = ''
      let step1ChildRegion = ''
      if (purchaseIntent.step1ChildResidenceCity) {
        const comuni = await api.getComuniExactByName(
          purchaseIntent.step1ChildResidenceCity
        )
        if (comuni.length > 0) {
          step1ChildResidenceCityObj = comuni[0]
          step1ChildResidenceCity = comuni[0].name
          step1ChildProv = comuni[0].car
          step1ChildRegion = comuni[0].region
        }
      }

      let step1ChildBirthDate = new Date(purchaseIntent.step1ChildBirthDate)
      if (!isValidDate(step1ChildBirthDate)) {
        console.log('invalid date', {
          step1ChildBirthDate,
          date: purchaseIntent.step1ChildBirthDate,
        })
        step1ChildBirthDate = null
      }

      setState(state => ({
        ...state,
        precompiling: false,
        division: '' + purchaseIntent.division,
        // step1ParentSurname:
        //   state.step1ParentSurname || purchaseIntent.step1ParentSurname,
        // step1ParentName:
        //   state.step1ParentName || purchaseIntent.step1ParentName,
        // step1ParentNationality:
        //   state.step1ParentNationality || purchaseIntent.step1ParentNationality,
        // TODO: check obj
        // step1ParentBirthCity:
        //   state.step1ParentBirthCity || purchaseIntent.step1ParentBirthCity,
        // step1ParentBirthProv:
        //   state.step1ParentBirthProv || purchaseIntent.step1ParentBirthProv,
        // step1ParentGender:
        //   state.step1ParentGender || purchaseIntent.step1ParentGender,
        // step1ParentFiscalCode:
        //   state.step1ParentFiscalCode || purchaseIntent.step1ParentFiscalCode,
        // step1ParentStreetAddress: purchaseIntent.step1ParentStreetAddress,
        // step1ParentResidenceCity: purchaseIntent.step1ParentResidenceCity,
        // step1ParentCAP: purchaseIntent.step1ParentCAP,
        // step1ParentProv: purchaseIntent.step1ParentProv,
        // step1ParentEmail: purchaseIntent.step1ParentEmail,
        // step1ParentPhone: purchaseIntent.step1ParentPhone,
        // step1ParentPhoneAlt: purchaseIntent.step1ParentPhoneAlt,
        // step1ParentBirthNation: purchaseIntent.step1ParentBirthNation,
        // step1ParentForeignBirthCity: purchaseIntent.step1ParentForeignBirthCity,
        step1ChildBirthNation: purchaseIntent.step1ChildBirthNation,
        step1ChildForeignBirthCity: purchaseIntent.step1ChildForeignBirthCity,
        step1ChildSurname: purchaseIntent.step1ChildSurname,
        step1ChildName: purchaseIntent.step1ChildName,
        step1ChildNationality: purchaseIntent.step1ChildNationality,
        step1ChildBirthCity,
        step1ChildBirthProv,
        step1ChildBirthDate,
        step1ChildGender: purchaseIntent.step1ChildGender === 'F' ? 'F' : 'M',
        step1ChildFiscalCode: purchaseIntent.step1ChildFiscalCode,
        step1ChildStreetAddress: purchaseIntent.step1ChildStreetAddress,
        step1ChildResidenceCityObj,
        step1ChildResidenceCity,
        step1ChildProv,
        step1ChildRegion,
        step1ChildCAP: purchaseIntent.step1ChildCAP,
        step1ChildEmail: purchaseIntent.step1ChildEmail,
        step1ChildPhone: purchaseIntent.step1ChildPhone,
        step1ChildPhoneAlt: purchaseIntent.step1ChildPhoneAlt,
        step12017Question: purchaseIntent.step12017Question || false,
        step12018Question: purchaseIntent.step12018Question || false,
        step12019Question: purchaseIntent.step12019Question || false,
        step12020Question: purchaseIntent.step12020Question || false,
        step12021Question: purchaseIntent.step12021Question || false,
        step1NeverQuestion: purchaseIntent.step1NeverQuestion || false,
        step2Participation:
          purchaseIntent.step2Participation != null
            ? '' + purchaseIntent.step2Participation
            : '',
        step2InpsServiceId:
          purchaseIntent.step2InpsServiceId != null
            ? '' + purchaseIntent.step2InpsServiceId
            : '',
        step2Grade:
          purchaseIntent.step2Grade != null ? purchaseIntent.step2Grade : '',
        step2BonusType: purchaseIntent.step2BonusType || '',
        step2BonusRequested: purchaseIntent.step2BonusRequested || '',
        step2BonusId: purchaseIntent.step2BonusId || null,
        step2BonusSurname: purchaseIntent.step2BonusSurname || '',
        step2BonusName: purchaseIntent.step2BonusName || '',
        step2Coupon: purchaseIntent.step2Coupon || '',
        step2AziendaConvenzionataNote:
          purchaseIntent.step2AziendaConvenzionataNote || '',
        step2bonuses: bonuses,
        inpsServices: contributiINPS,
      }))
      psDispatch(
        psActions.initAll(
          catalogs || [],
          purchaseIntent.step1Catalog,
          destinations || [],
          purchaseIntent.step1Nation,
          packages || [],
          purchaseIntent.step1PackageGroup,
          turns || [],
          purchaseIntent.step1Package,
          cities || [],
          purchaseIntent.step1DepartureServiceItems
            ? '' + purchaseIntent.step1DepartureServiceItems
            : null,
          structures || [],
          purchaseIntent.step1Structure
            ? '' + purchaseIntent.step1Structure
            : null,
          programTypologies || [],
          purchaseIntent.step1ProgramTypology
            ? '' + purchaseIntent.step1ProgramTypology
            : null
        )
      )
    } catch (error) {
      console.error(error)
      failPrecompiled()
    }
  }, [api, isInitDone, psDispatch, setState, state.originPracticeId, token])

  return {
    initPrecompiled,
    failPrecompiled,
  }
}
