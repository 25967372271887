const getErrors = (data, errors = {}) => {
  if (data.children) {
    // è una collection di altri form
    if (data.children instanceof Array) {
      return data.children.map(child => getErrors(child))
    }

    const isNumeric = Object.keys(data.children).some(key => !isNaN(key))

    if (isNumeric) {
      let children = Object.keys(data.children).map(key => data.children[key])
      return children.map(child => getErrors(child))
    }

    for (const field in data.children) {
      if (data.children[field].errors) {
        errors[field] = data.children[field].errors
      }
      if (data.children[field].children) {
        errors[field] = getErrors(data.children[field])
      }
    }
  }

  return errors
}

export default getErrors
