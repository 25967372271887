import {useContext, useCallback} from 'react'
import queryString from 'query-string'
import {PackageSelectorContext} from '../contexts/PackageSelectorContext'
import * as actions from '../contexts/PackageSelectorActions'
import useApi from './useApi'

export default function usePackageSelectorInit() {
  const [state, dispatch] = useContext(PackageSelectorContext)
  const api = useApi()

  function handleGenericError(errorActionMsg = 'generico') {
    alert(
      `Si è verificato un errore ${errorActionMsg}. Si prega di riprovare più tardi o contatta uno dei seguenti numeri: 0736343440 oppure 0736336339.`
    )
  }

  const init = useCallback(async () => {
    const {division = '1', country, resortTheme = '1', webgroup, catalog, type} = queryString.parse(
      window.location.search
    )

    let errorActionMsg

    // console.info("catalog", catalog);
    
    let catalogs
    if(type === 'hsp'){
      //Solo e soltanto in questo caso effettuo il fetch dei cataloghi
      try {
        catalogs = await api.getCatalogs()
        // console.info("catalogs", catalogs);
        // console.info("catalog", catalog);
      } catch (error) {
        console.error('Impossible to get catalogs')
        console.error(error)
        catalogs = []
        errorActionMsg = 'nel recupero della lista dei cataloghi'
      }

      console.log("2023A100", "usePackageSelecontInit", "pre-nations")
      console.log("2023A100", "usePackageSelecontInit", "catalog", catalog)
      // catalog code not found, setting only division and catalogs
      if (!catalog || !catalogs.map(n => n.value).includes(parseInt(catalog))) {
        // console.info("dispatch(actions.setCatalogs(catalogs))");
        dispatch(actions.setCatalogs(catalogs))
        if (errorActionMsg) {
          handleGenericError(errorActionMsg)
        }
        return
      }
    }

    let nations
    try {
      nations = catalog ? await api.getNationsByCatalog(division, catalog) : await api.getNations(division)
    } catch (error) {
      console.error('Impossible to get nations')
      console.error(error)
      nations = []
      errorActionMsg = 'nel recupero della lista delle nazioni'
    }

    // country code not found, setting only division and nations
    if (!country || !nations.map(n => n.value).includes(country)) {
      // console.info("dispatch(actions.setNations(nations))")
      if(catalog){
        dispatch(actions.init(catalogs, catalog, nations, country))
      }else{
        dispatch(actions.setNations(nations))
      }
      if (errorActionMsg) {
        handleGenericError(errorActionMsg)
      }
      return
    }

    let webGroups
    try {
      if (division === "3") {
        webGroups = await api.getPackageGroups(country, division, resortTheme)
      } else {
        webGroups = await api.getPackageGroups(country, division)
      }
    } catch (error) {
      console.error('Impossible to get webGroups')
      console.error(error)
      webGroups = []
      errorActionMsg = 'nel recupero della lista dei programmi'
    }

    // webgroup code not found, setting only division, nations, selected nation and webGroups
    if (!webgroup || !webGroups.map(g => g.value).includes(webgroup)) {
      dispatch(actions.init(catalogs, catalog, nations, country, webGroups))
      if (errorActionMsg) {
        handleGenericError(errorActionMsg)
      }
      return
    }

    dispatch(
      actions.init(catalogs, catalog, nations, country, webGroups, webgroup, true, resortTheme)
    )

    if (errorActionMsg) {
      handleGenericError(errorActionMsg)
    }
  }, [dispatch, api])

  return {state, init}
}
