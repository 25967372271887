import React from 'react'
import StepperWrapper from './components/StepperWrapper'
import {DialogContextProvider} from "../contexts/DialogContext";

export default function HspApp() {
  return (
      <DialogContextProvider>
        <StepperWrapper />
      </DialogContextProvider>
  )
}
