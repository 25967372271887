import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
// import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import FormControl from '@material-ui/core/FormControl'
// import FormGroup from '@material-ui/core/FormGroup'
// import Checkbox from '@material-ui/core/Checkbox'
import {participationsLookup} from '../../specificUtils/configs'
import Typography from '@material-ui/core/Typography'
import Voucher from './Voucher'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
  textField8: {
    width: '62.666667%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField12: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
}))

export default function StepBonusCorporate(props) {
  const {
    step2WelfareType,
    step2AziendaConvenzionataNote,
    onEventChange,
    step1ChildFiscalCode,
    step2VoucherCode,
    step2VoucherCodes,
    // onCheckboxChange,
  } = props
  const classes = useStyles()
  return (
    <>
      <div className="box-light boxed">
        <Grid container>
          <Grid item xs={12}>
            {/* <FormControl
            component="fieldset"
            className={classes.formControl + ' inline-block radio-inline'}>
            <FormLabel component="legend">
              Seleziona il tipo di partecipazione
            </FormLabel>

            <FormGroup className={classes.group}>
              <FormControlLabel
                label="Partecipo tramite portale Welfare"
                control={
                  <Checkbox
                    checked={step2WelfareType === participationsLookup.WELFARE}
                    onChange={onCheckboxChange('step2WelfareType')}
                    value={participationsLookup.WELFARE}
                  />
                }
              />
              <FormControlLabel
                label="Partecipo con azienda convenzionata"
                control={
                  <Checkbox
                    checked={
                      step2WelfareType ===
                      participationsLookup.AZIENDA_CONVENZIONATA
                    }
                    onChange={onCheckboxChange('step2WelfareType')}
                    value={participationsLookup.AZIENDA_CONVENZIONATA}
                  />
                }
              />
            </FormGroup>
          </FormControl> */}

            {[participationsLookup.AZIENDA_CONVENZIONATA].includes(
              step2WelfareType
            ) && (
              <>
                <Typography variant="h5">
                  Inserisci le indicazioni per il rimborso Welfare / Portale Welfare
                </Typography>
                { /*
                <Typography variant="body1">
                  Ai fini della verifica dell'appartenenza all'Azienda
                  Convenzionata la Giocamondo potrebbe richiedere ulteriori
                  documenti a completamento della prenotazione.
                </Typography>
                */ }
                <TextField
                  id="code-azienda-convenzionata-note"
                  label="Inserisci la dicitura rimborso Welfare / nome del tuo portale Welfare"
                  className={classes.textField12}
                  value={step2AziendaConvenzionataNote}
                  onChange={onEventChange('step2AziendaConvenzionataNote')}
                  margin="normal"
                  multiline
                  //rows="4"
                />
              </>
            )}
            <Voucher
              step1ChildFiscalCode={step1ChildFiscalCode}
              onChange={onEventChange}
              value={step2VoucherCode}
              values={step2VoucherCodes}
            />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

StepBonusCorporate.propTypes = {
  step2WelfareType: PropTypes.string,
  step2AziendaConvenzionataNote: PropTypes.string,
  onEventChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
}
