import React from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from './MemebrshipInfoIcon'
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  iconWrapper: {
    display: 'inline',
    margin: theme.spacing(0, 1),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: 'white',
  },
  textAction: {
    color: '#FC7200',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
}))
const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
      },
    },
  },
})

function MembershipIntro({onLogin, onSignUp}) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <Typography variant="body1">
          Sei già registrato a <strong>GiocamondoClub?</strong>
          <MuiThemeProvider theme={theme}>
            <Tooltip title="Sei già registrato? Effetua il login. Ricorda di inserire l’email del genitore che ha effettuato la registrazione. Oppure prosegui e registrati. Importante: la registrazione all’area Giocamondo Club è obbligatoria per completare la prenotazione.">
              <span className={classes.iconWrapper}>
                <InfoIcon style={{color: '#00B219'}} />
              </span>
            </Tooltip>
          </MuiThemeProvider>
        </Typography>
        <Typography variant="body2">
          <br />
          Ti ricordiamo che se sei già registrato, inserendo le tue credenziali,{' '}
          <strong>
            molti dei campi sottostanti saranno automaticamente compilati
          </strong>
          .<br />
          {/* Se hai ricevuto la nostra e-mail di accesso utilizza i dati che ti
          abbiamo inviato oppure compila il form di registrazione rispondendo
          “No”. */}
          <strong>Importante per partecipanti INPS: </strong>la prenotazione deve essere effettuata a nome del titolare INPS (CF ed email) il quale deve essere anche lo stesso iscritto al GiocamondoClub.
          <br/>
          <strong>NB: </strong> l’iscrizione al Giocamondo Club ti permetterà di gestire tutta la documentazione necessaria alla conferma della pratica.
          
        </Typography>
        <div>
          <FormControlLabel
            value="si"
            label="Sì"
            control={<Radio onChange={onLogin} />}
          />

          <FormControlLabel
            value="no"
            label="No"
            control={<Radio onChange={onSignUp} />}
          />
        </div>
      </div>

      <div className={classes.root}>
        <a
          id="inserimentoDati"
          href="#inserimentoDati"
          className={classes.link}>
          <Typography
            className={`${classes.iconWrapper} ${classes.textAction}`}
            component="span">
            &darr;
          </Typography>
          <Typography component="span" variant="body2">
            Oppure prosegui senza registrazione
          </Typography>
        </a>
      </div>
    </React.Fragment>
  )
}

MembershipIntro.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired,
}

export default MembershipIntro
