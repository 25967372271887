import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import StepPaymentMethod from './StepPaymentMethod'

export default function StepBonusPrivate(props) {
    return (
        <div className="box-light boxed">
            <Grid container spacing={3} className="panel" alignItems="center">
                <>
                    <Grid item xs={12} sm={1}></Grid>
                    <Grid item xs={12} sm={10}>
                        <Typography variant="subtitle2" className="align-center">
                            Segui i passaggi successivi per concludere la prenotazione.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={1}></Grid>
                    <StepPaymentMethod {...props} />
                </>
            </Grid>
        </div>
    )
}

StepBonusPrivate.propTypes = {
    bonuses: PropTypes.arrayOf(
        PropTypes.shape({
            bonustype: PropTypes.string.isRequired,
        })
    ),
    step2BonusRequested: PropTypes.bool,
    step2BonusType: PropTypes.any,
}
