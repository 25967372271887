import React from 'react'
import PropTypes from 'prop-types'

// material-ui components
import {makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
// local components
import Anagrafica from 'components/Anagrafica'
import DateComponent from 'components/DateComponent'
import ResidenceAutocomplete from 'components/ResidenceAutocomplete'
import StepCustomerDataNotice from './StepCustomerDataNotice'
import Membership from 'components/Membership'
import {participations} from '../../specificUtils/configs'
import PackageSelectorWrapper from '../PackageSelectorWrapper'
import {birthNationalityType, addDays} from './utils'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textField12: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  textField11: {
    width: '91.666667%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField10: {
    width: '83.333333%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField8: {
    width: '62.666667%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField6: {
    width: '46.666%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField4: {
    width: '32.333333%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField3: {
    width: '23.66666%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField2: {
    width: '16.666667%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  errorMessage: {
    color: 'red',
  },
}))

export default function StepCustomerData(props) {
  const classes = useStyles()
  const {
    state,
    isUserLogged = false,
    onPasswordChange,
    onEventChange,
    onValueChange,
    onParticipationChange,
    onBirthNationalityChange,
    onParentBirthCityChange,
    onChildBirthCityChange,
    onParentResidenceCityChange,
    onChildResidenceCityChange,
    onSameResidenceChange,
    onPrivacyChange,
    onChangeFlDocInserted,
  } = props

  const errors = state.errors || {}
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <StepCustomerDataNotice division={state.division} />
      </Grid>
      <Grid item xs={12} className="box-well">
        <PackageSelectorWrapper title="Programma scolastico all'estero" />
      </Grid>
      <Grid item xs={12}>
        <Membership source={'main'}/>
      </Grid>
      <Grid item xs={12}>
        <Grid container className="bordered-bottom">
          <Typography variant="h6">Dati del Genitore</Typography>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <TextField
              id="standard-select-participation"
              select
              className={
                classes.textField4 + 'select col-xs-12 lateral-margin-8px'
              }
              value={state.step2Participation || ''}
              error={!!errors && !!errors.step2Participation}
              onChange={evt => onParticipationChange(evt.target.value)}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu,
                },
              }}
              helperText="Seleziona tipo di partecipazione"
              margin="normal">
              <option value="">Seleziona</option>
              {participations.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Anagrafica
          surname={state.step1ParentSurname}
          surnameError={errors.step1ParentSurname}
          handleSurnameChange={onValueChange('step1ParentSurname')}
          name={state.step1ParentName}
          nameError={errors.step1ParentName}
          handleNameChange={onValueChange('step1ParentName')}
          gender={state.step1ParentGender}
          genderError={errors.step1ParentGender}
          handleGenderChange={onValueChange('step1ParentGender')}
          nationality={state.step1ParentNationality}
          nationalityError={errors.step1ParentNationality}
          handleNationalityChange={onBirthNationalityChange(
            'step1ParentNationality',
            birthNationalityType.PARENT
          )}
          birthNation={state.step1ParentBirthNation}
          birthNationError={errors.step1ParentBirthNation}
          handleBirthNationChange={onValueChange('step1ParentBirthNation')}
          foreignBirthCity={state.step1ParentForeignBirthCity}
          foreignBirthCityError={errors.step1ParentForeignBirthCity}
          handleForeignBirthCityChange={onValueChange(
            'step1ParentForeignBirthCity'
          )}
          birthCity={state.step1ParentBirthCity}
          birthCityError={errors.step1ParentBirthCity}
          handleBirthCityChange={onParentBirthCityChange}
          birthProvince={state.step1ParentBirthProv}
          birthProvinceError={errors.step1ParentBirthProv}
          handleBirthProvinceChange={onValueChange('step1ParentBirthProv')}
          birthDate={state.step1ParentBirthDate}
          birthDateError={errors.step1ParentBirthDate}
          handleBirthDateChange={onValueChange('step1ParentBirthDate')}
          fiscalCode={state.step1ParentFiscalCode}
          fiscalCodeError={errors.step1ParentFiscalCode}
          handleFiscalCodeChange={onValueChange('step1ParentFiscalCode')}
          fiscalCodeDesc={
            isUserLogged
                ? ''
                : 'Attenzione ricontrolla il codice fiscale che sarà utilizzato in tutti i documenti a te intestati'
          }
          disableFiscalCode={isUserLogged}
          email={state.step1ParentEmail}
          emailError={errors.step1ParentEmail}
          emailRequired
          handleEmailChange={onValueChange('step1ParentEmail')}
          phone={state.step1ParentPhoneAlt}
          phoneError={errors.step1ParentPhoneAlt}
          handlePhoneChange={onValueChange('step1ParentPhoneAlt')}
          cellPhone={state.step1ParentPhone}
          cellPhoneError={errors.step1ParentPhone}
          cellPhoneRequired
          handleCellPhoneChange={onValueChange('step1ParentPhone')}
          cellPhoneDesc="Inserire numero di cellulare per ricevere comunicazioni importanti relative alla pratica"
          emailDesc="La mail inserita sarà il tuo username per accedere all'Area Club"
          passwords={!state.memberExists && !isUserLogged && (state.step1ParentFiscalCode.length === 16)}
          onChangePassword={onPasswordChange}
        />

        <br />

        <Grid container>
          <Typography variant="h6">Residenza</Typography>
        </Grid>

        <ResidenceAutocomplete
          styles={classes}
          streetAddress={state.step1ParentStreetAddress}
          streetAddressError={errors && errors.step1ParentStreetAddress}
          onStreetAddressChange={onValueChange('step1ParentStreetAddress')}
          city={state.step1ParentResidenceCityObj}
          cityError={errors && errors.step1ParentResidenceCity}
          onCityDelete={() => onParentResidenceCityChange(null)}
          onCitySelect={onParentResidenceCityChange}
          cap={state.step1ParentCAP}
          capError={errors && errors.step1ParentCAP}
          onCapChange={onValueChange('step1ParentCAP')}
          province={state.step1ParentProv}
          provinceError={errors && errors.step1ParentProv}
          onProvinceChange={onValueChange('step1ParentProv')}
          onRegionChange={onValueChange('step1ParentRegion')}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container className="bordered-bottom">
          <Typography variant="h6">Dati del partecipante</Typography>
        </Grid>
        <Anagrafica
          surname={state.step1ChildSurname}
          surnameError={errors.step1ChildSurname}
          handleSurnameChange={onValueChange('step1ChildSurname')}
          name={state.step1ChildName}
          nameError={errors.step1ChildName}
          handleNameChange={onValueChange('step1ChildName')}
          gender={state.step1ChildGender}
          genderError={errors.step1ChildGender}
          handleGenderChange={onValueChange('step1ChildGender')}
          nationality={state.step1ChildNationality}
          nationalityError={errors.step1ChildNationality}
          handleNationalityChange={onBirthNationalityChange(
            'step1ChildNationality',
            birthNationalityType.CHILD
          )}
          birthNation={state.step1ChildBirthNation}
          birthNationError={errors.step1ChildBirthNation}
          handleBirthNationChange={onValueChange('step1ChildBirthNation')}
          foreignBirthCity={state.step1ChildForeignBirthCity}
          foreignBirthCityError={errors.step1ChildForeignBirthCity}
          handleForeignBirthCityChange={onValueChange(
            'step1ChildForeignBirthCity'
          )}
          birthCity={state.step1ChildBirthCity}
          birthCityError={errors.step1ChildBirthCity}
          handleBirthCityChange={onChildBirthCityChange}
          birthProvince={state.step1ChildBirthProv}
          birthProvinceError={errors.step1ChildBirthProv}
          handleBirthProvinceChange={onValueChange('step1ChildBirthProv')}
          birthDate={state.step1ChildBirthDate}
          birthDateError={errors.step1ChildBirthDate}
          handleBirthDateChange={onValueChange('step1ChildBirthDate')}
          fiscalCode={state.step1ChildFiscalCode}
          fiscalCodeError={errors.step1ChildFiscalCode}
          handleFiscalCodeChange={onValueChange('step1ChildFiscalCode')}
          email={state.step1ChildEmail}
          emailError={errors.step1ChildEmail}
          emailRequired
          handleEmailChange={onValueChange('step1ChildEmail')}
          phone={state.step1ChildPhone}
          phoneError={errors.step1ChildPhone}
          handlePhoneChange={onValueChange('step1ChildPhone')}
          cellPhone={state.step1ChildCellPhone}
          cellPhoneError={errors.step1ChildCellPhone}
          handleCellPhoneChange={onValueChange('step1ChildCellPhone')}
          cellPhoneRequired
        />
        <br />
        <Grid item xs={12}>
          <Typography variant="h6">Documento</Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              Vuoi fornirci sin da subito i dati del documento del Partecipante?
            </FormLabel>
            <RadioGroup
                name="disable"
                className={classes.group}
                value={state.flDocInserted}
                onChange={onChangeFlDocInserted('flDocInserted')}>
              <FormControlLabel value="1" control={<Radio/>} label="Sì"/>
              <FormControlLabel value="0" control={<Radio/>} label="No, li comunicherò successivamente"/>
            </RadioGroup>
          </FormControl>
        </Grid>


        {state.flDocInserted === '1' && <>
          <Grid item xs={12}>
            <FormControl
                component="fieldset"
                error={errors && errors.step1ChildDocType}>
              <FormHelperText>
                {errors && errors.step1ChildDocType
                    ? errors.step1ChildDocType || ' richiesto'
                    : null}
              </FormHelperText>
              <RadioGroup
                  aria-label="doc-type"
                  id="doc-type"
                  value={state.step1ChildDocType || ''}
                  onChange={onValueChange('step1ChildDocType')}
                  error={errors && errors.step1ChildDocType ? ' richiesto' : null}
                  row>
                <FormControlLabel
                    value="idCard"
                    control={<Radio color="primary"/>}
                    label="Carta d'identità"
                    labelPlacement="end"
                />
                <FormControlLabel
                    value="passport"
                    control={<Radio color="primary"/>}
                    label="Passaporto"
                    labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid container>
            <Grid item md={6} xs={12}>
              <TextField
                  disabled={!state.step1ChildDocType}
                  id="doc-number"
                  label="Numero documento *"
                  className={classes.textField11 + ' col-xs-12'}
                  value={state.step1ChildDocNumber || ''}
                  error={!!errors && !!errors.step1ChildDocNumber}
                  onChange={onValueChange('step1ChildDocNumber')}
                  margin="normal"
                  helperText={
                    errors && errors.step1ChildDocNumber
                        ? errors.step1ChildDocNumber || ' richiesto'
                        : null
                  }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                  disabled={!state.step1ChildDocNumber}
                  id="doc-country"
                  label="Nazionalità riportata sul documento"
                  className={classes.textField11 + ' col-xs-12'}
                  value={state.step1ChildDocCountry || ''}
                  onChange={onValueChange('step1ChildDocCountry')}
                  margin="normal"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <FormControl error={errors && !!errors.step1ChildDocEmission}>
                <FormLabel>Data emissione * </FormLabel>
                <DateComponent
                    label="Data emissione"
                    onChange={onValueChange('step1ChildDocEmission')}
                    value={state.step1ChildDocEmission}
                    error={errors && !!errors.step1ChildDocEmission}
                />
                <FormHelperText>
                  {errors && errors.step1ChildDocEmission
                      ? errors.step1ChildDocEmission || ' richiesto'
                      : null}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl error={errors && !!errors.step1ChildDocExpiration}>
                <FormLabel> Data scadenza * </FormLabel>
                <DateComponent
                    disabled={!state.step1ChildDocEmission}
                    label="Data scadenza"
                    onChange={onValueChange('step1ChildDocExpiration')}
                    value={state.step1ChildDocExpiration}
                    error={errors && !!errors.step1ChildDocExpiration}
                    disabledDays={[
                      {
                        before: addDays(state.step1ChildDocEmission, 1),
                      },
                    ]}
                />
                <FormHelperText>
                  {errors && errors.step1ChildDocExpiration
                      ? errors.step1ChildDocExpiration || ' richiesto'
                      : null}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </>}

        <br />
        <Grid item xs={12}>
          <Typography variant="h6">Residenza del partecipante</Typography>
        </Grid>
        <Grid container spacing={2}>
          <br />
          <Grid item xs={12}>
            Residente con il genitore{' '}
            <input
              type="checkbox"
              onChange={onSameResidenceChange}
              checked={state.step1SameResidence}
            />
          </Grid>

          <ResidenceAutocomplete
            disabled={state.step1SameResidence}
            styles={classes}
            streetAddress={state.step1ChildStreetAddress}
            streetAddressError={errors && errors.step1ChildStreetAddress}
            onStreetAddressChange={onValueChange('step1ChildStreetAddress')}
            city={state.step1ChildResidenceCityObj}
            cityError={errors && errors.step1ChildResidenceCity}
            onCityDelete={() => onChildResidenceCityChange(null)}
            onCitySelect={onChildResidenceCityChange}
            cap={state.step1ChildCAP}
            capError={errors && errors.step1ChildCAP}
            onCapChange={onValueChange('step1ChildCAP')}
            province={state.step1ChildProv}
            provinceError={errors && errors.step1ChildProv}
            onProvinceChange={onValueChange('step1ChildProv')}
            onRegionChange={onValueChange('step1ChildRegion')}
          />
        </Grid>
        <Grid container>
          <br />
          <Grid container spacing={2} className="box-pp">
            <Grid item xs={12}>
              <p>
                <small>
                  Informativa estesa sulla
                  {state.division === '1' ? (
                    <a
                      href="https://www.iubenda.com/privacy-policy/656885"
                      title="Informativa privacy Giocamondo Study"
                      rel="noopener noreferrer"
                      target="_blank">
                      {' '}
                      privacy
                    </a>
                  ) : (
                    <a
                      href="https://www.iubenda.com/privacy-policy/411930"
                      title="Informativa privacy La Mia Estate"
                      rel="noopener noreferrer"
                      target="_blank">
                      {' '}
                      privacy
                    </a>
                  )}
                  . Ai sensi e per gli effetti dell'art.4 DPR 445/2000, in relazione al rilascio di dichiarazioni mendaci, dichiaro che le informazioni rese nel presente form corrispondono a verità e accetto che queste vengano utilizzate unicamente per le finalità per le quali sono state acquisite (GDPR - Regolamento 2016/679). Per quanto riguarda i consensi da lei sottoscritti le comunichiamo che limiteremo le nostre attività sui vostri dati, per le finalità indicate nell’informativa, entro i limiti delle autorizzazioni che ci avete concesso e al fine di verificare i consensi sottoscritti può inviare una mail al seguente indirizzo:
                  {state.division === '1' ? (
                    <a
                      href="mailto:privacy@giocamondostudy.it"
                      title="Mail per verificare i consensi sottoscritti">
                      privacy@giocamondostudy.it
                    </a>
                  ) : (
                    <a
                      href="mailto:privacy@giocamondo.it"
                      title="Mail per verificare i consensi sottoscritti">
                      privacy@giocamondo.it
                    </a>
                  )}
                  . L'accettazione è obbligatoria per poter procedere all'invio
                  dei dati.
                </small>
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.step4Privacy}
                      onChange={evt => onPrivacyChange(evt.target.checked)}
                    />
                  }
                  label="Dichiaro di aver preso visione della privacy policy"
                />
              </p>
            </Grid>

            <Grid item xs={12}>
              <p>
                <small>
                  Consenti il trattamento dei Tuoi dati personali per il
                  ricevimento della Newsletter aziendale, di comunicazioni
                  relative a nostri servizi, prodotti, promozioni per finalità
                  di marketing diretto?
                  {state.division === '1' ? (
                    <a
                      href="https://www.iubenda.com/privacy-policy/656885"
                      title="Informativa privacy Giocamondo Study"
                      rel="noopener noreferrer"
                      target="_blank">
                      {' '}
                      Leggi qui{' '}
                    </a>
                  ) : (
                    <a
                      href="https://www.iubenda.com/privacy-policy/411930"
                      title="Informativa privacy La Mia Estate"
                      rel="noopener noreferrer"
                      target="_blank">
                      {' '}
                      Leggi qui{' '}
                    </a>
                  )}
                  l'informativa estesa sulla privacy per maggiori dettagli.
                </small>
              </p>
              <RadioGroup
                aria-label="position"
                name="position"
                value={state.step4Marketing}
                onChange={onValueChange('step4Marketing')}
                row>
                <FormControlLabel
                  value="si"
                  control={<Radio color="primary" />}
                  label="Acconsento"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="Non Acconsento"
                  labelPlacement="end"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

StepCustomerData.propTypes = {
  state: PropTypes.object.isRequired,
  onEventChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onParticipationChange: PropTypes.func.isRequired,
  onBirthNationalityChange: PropTypes.func.isRequired,
  onParentBirthCityChange: PropTypes.func.isRequired,
  onChildBirthCityChange: PropTypes.func.isRequired,
  onParentResidenceCityChange: PropTypes.func.isRequired,
  onChildResidenceCityChange: PropTypes.func.isRequired,
  onSameResidenceChange: PropTypes.func.isRequired,
  onPrivacyChange: PropTypes.func.isRequired,
}
