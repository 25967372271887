import React, {useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import usePayment from 'hooks/usePayment'
import {AppContext} from 'contexts/AppContext'
import {PaymentContext} from 'contexts/PaymentContext'
import PaymentUI from './Payment'

export default function Payment(props) {
  const [appState] = useContext(AppContext)
  const [paymentState, setPaymentState] = useContext(PaymentContext)
  const {
    finalizeBankTransferOrder,
    startPayment,
    // finishPaymentWithSuccess,
    finishPaymentWithError,
    handleBankTransfer,
    handlePaypalTransfer,
    getPaypalClientId,
    finalizePaypalOrder,
    markAsPaymentError,
  } = usePayment()
  const {paypalAnswered} = paymentState
  const amount = `${(props.amount || 0) / 100}`

  useEffect(() => {
    if (!paymentState.paypalClientId) {
      getPaypalClientId()
    }
  }, [getPaypalClientId, paymentState.paypalClientId])

  async function handleFinalizeBankTransferOrder() {
    startPayment()
    try {
      await finalizeBankTransferOrder()
    } catch (ex) {
      if (!paymentState.payError) {
        finishPaymentWithError(ex)
      }
    }
  }

  async function handlePaypalPaidOrder(orderId) {
    startPayment()
    await finalizePaypalOrder(orderId)
  }

  function handlePaypalAnswered(value) {
    setPaymentState(state => ({...state, paypalAnswered: value}))
  }

  // const billingAddress = {
  //   givenName: appState.step1ParentName,
  //   surname: appState.step1ParentSurname,
  //   phoneNumber: (appState.step1ParentCellPhone || '').replace(/[()\s-]/g, ''),
  //   streetAddress: appState.step1ParentStreetAddress,
  //   extendedAddress: appState.step1ParentStreetAddress,
  //   locality: appState.step1ParentResidenceCity,
  //   region: 'Italia',
  //   postalCode: appState.step1ParentCAP,
  //   countryCodeAlpha2: 'IT',
  // }


  return (
    <PaymentUI
      // clientToken={clientToken}
      // clientTokenError={clientTokenError}
      paid={paymentState.paid}
      paying={paymentState.paying}
      payError={paymentState.payError}
      onFinalizeBankTransferOrder={handleFinalizeBankTransferOrder}
      onPaypalPaidOrder={handlePaypalPaidOrder}
      // billingAddress={billingAddress}
      amount={amount}
      email={appState.step1ParentEmail}
      onBankTransfer={handleBankTransfer}
      bankTransfer={paymentState.bankTransfer}
      paypalTransfer={paymentState.paypalTransfer}
      onPaypalTransfer={handlePaypalTransfer}
      step2Participation={appState.step2Participation}
      division={appState.division}
      paypalClientId={paymentState.paypalClientId}
      onMarkAsPaymentError={markAsPaymentError}
      paypalAnswered={paypalAnswered}
      onPaypalAnswered={handlePaypalAnswered}
    />
  )
}

Payment.propTypes = {
  amount: PropTypes.number.isRequired,
}
