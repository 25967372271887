import React from 'react'
import Sidebar from 'components/Sidebar'
import {stepsByName} from '../specificUtils/configs'

export default function SidebarWrapper() {
  return (
    <Sidebar
      packageTitle="Seleziona periodo"
      summaryStepIndex={stepsByName.RECAP}
      enableTagManager
    />
  )
}
