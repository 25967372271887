import React, {useContext} from 'react'
import StepExtraServicesUI from './StepExtraServices'
import {AppContext} from '../../../contexts/AppContext'

export default function StepExtraServices() {
  const [state, setState] = useContext(AppContext)
  const {
    division,
    step4Privacy,
    step4Marketing,
    step4Profilation,
    step4Profilation2,
    errors,
  } = state
  const handleChangeValue = (label, value) => {
    setState(state => ({...state, [label]: value}))
  }

  return (
    <StepExtraServicesUI
      onChangeValue={handleChangeValue}
      errors={errors}
      division={division}
      step4Privacy={step4Privacy}
      step4Marketing={step4Marketing}
      step4Profilation={step4Profilation}
      step4Profilation2={step4Profilation2}
    />
  )
}
