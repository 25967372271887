import React, {useReducer} from 'react'
import {initialState, reducer} from './PackageSelectorReducer'

const PackageSelectorContext = React.createContext([{}, () => {}])

const PackageSelectorProvider = props => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    resortTheme: props.resortTheme || null,
  })

  return (
    <PackageSelectorContext.Provider value={[state, dispatch]}>
      {props.children}
    </PackageSelectorContext.Provider>
  )
}

export {PackageSelectorContext, PackageSelectorProvider}
