import React, {useEffect, useState} from 'react'
import Notice from 'components/Notice'
import {getBookingFormNotice} from "../../specificUtils/api";

export default function StepCustomerDataNotice(props) {
    const [notice, setNotice] = useState();
    const {division} = props;

    const fetchData = async () => {
        try {
            const response = await getBookingFormNotice(division);
            if (response && response.data && response.data.enabled) {
                setNotice(response.data.text);
            }
        } catch (error) {
            console.error(error.response);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    return notice ? (
        <Notice>
            <div dangerouslySetInnerHTML={{__html: notice}}/>
            {/*<small className={clsx('heelp', 'help-text', classes.upperText)}>*/}
            {/*  Apertura pratica.*/}
            {/*</small>*/}
            {/*<h3 className={classes.header}>*/}
            {/*  Attenzione! Il modulo seguente non è una richiesta di preventivo.*/}
            {/*</h3>*/}
            {/*<p>*/}
            {/*  Aprire la pratica vuol dire infatti iscriversi e riservare un posto per*/}
            {/*  la destinazione prescelta con il programma Anno all'Estero. <br />*/}
            {/*  L'iscrizione si considera perfezionata solo se tutti i seguenti passaggi*/}
            {/*  vengono effettuati:*/}
            {/*</p>*/}
            {/*<p></p>*/}
            {/*<ul>*/}
            {/*  <li>compilazione del form sottostante</li>*/}
            {/*  <li>*/}
            {/*    inoltro della scheda riepilogativa firmata all'indirizzo*/}
            {/*    annoallestero@giocamondo.it*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    pagamento della quota di apertura pratica di € 150 (tale quota è già*/}
            {/*    inclusa nel prezzo totale del programma)*/}
            {/*  </li>*/}
            {/*</ul>*/}
            {/*<h4 className={classes.header}>*/}
            {/*  PER CHI PARTECIPA AL PROGRAMMA ITACA INPS:*/}
            {/*</h4>*/}
            {/*<p>*/}
            {/*  qualora lo studente non ottenga la borsa di studio ITACA può annullare*/}
            {/*  l'iscrizione senza alcuna penale e ottenere il*/}
            {/*  <strong>*/}
            {/*    rimborso della quota di apertura pratica versata di € 150*/}
            {/*  </strong>*/}
            {/*</p>*/}
            {/*<h4 className={classes.header}>*/}
            {/*  Sei in possesso di un voucher?*/}
            {/*</h4>*/}
            {/*<p>*/}
            {/*  Inserisci le informazioni relative al voucher nel campo NOTE, che si trova al termine della prenotazione. Il nostro staff provvederà, in seguito alla prenotazione, a detrarre il valore del voucher dalla vostra pratica*/}
            {/*</p>*/}
        </Notice>
    ) : <></>
}
