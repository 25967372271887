import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'
import ServerAutocomplete from './Autocomplete'
import DateComponent from './DateComponent'
import useApi from '../hooks/useApi'

const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: 'red',
  },
  autocompleteContainer: {
    marginTop: theme.spacing(3),
  },
  dateContainer: {
    marginBottom: theme.spacing(1),
  },
  dateinput:{
    fontSize: '1.2rem',
    color: 'rgba(0, 0, 0, 1)!important',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)!important'
  }

}))

function BirthPlaceInput(props) {
  const api = useApi()
  const classes = useStyles()
  const {
    birthCity,
    birthCityError,
    handleBirthCityChange,
    birthProvince,
    birthProvinceError,
    handleBirthProvinceChange,
    birthDate,
    birthDateError,
    handleBirthDateChange,
  } = props

  return (
    <Grid container spacing={2} alignItems="flex-start">
      {!!birthCityError && (
        <span className="birthcity-required">Richiesto</span>
      )}
      <Grid
        item
        xs={12}
        sm={9}
        md={6}
        className={classes.autocompleteContainer}>
        <ServerAutocomplete
          value={birthCity}
          placeholder="Nato a *"
          onDelete={() => handleBirthCityChange(null)}
          onSelect={evt => handleBirthCityChange(evt)}
          getOptions={api.getComuniByName}
        />
        {!!birthCityError && (
          <FormHelperText className={classes.errorMessage}>
            richiesto*
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          label="Prov"
          value={birthProvince}
          InputLabelProps={{shrink: !!birthProvince}}
          onChange={evt => handleBirthProvinceChange(evt.target.value)}
          error={birthProvinceError}
          margin="normal"
          helperText={!!birthProvinceError && 'richiesto*'}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} className={classes.dateContainer}>
        <FormControl>
          {/* <InputLabel htmlFor="date-component">Data di nascita</InputLabel> */}
          <DateComponent
            className={classes.dateinput}
            onChange={handleBirthDateChange}
            value={birthDate}
            error={birthDateError}
          />
          <FormHelperText>
            Scrivi la data come da esempio 27/01/1993
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  )
}

BirthPlaceInput.propTypes = {
  birthCity: PropTypes.object,
  birthCityError: PropTypes.any,
  handleBirthCityChange: PropTypes.func.isRequired,
  birthProvince: PropTypes.string,
  birthProvinceError: PropTypes.any,
  handleBirthProvinceChange: PropTypes.func.isRequired,
  birthDate: PropTypes.instanceOf(Date),
  birthDateError: PropTypes.any,
  handleBirthDateChange: PropTypes.func.isRequired,
}

export default BirthPlaceInput
