import React from 'react'
// import {makeStyles} from '@material-ui/core/styles'
import {Alert, AlertTitle} from '@material-ui/lab'

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }))

export default function StepPaymentSuccess(props) {
  // const classes = useStyles()
  const {
    title = 'Grazie per aver scelto un soggiorno estivo GIOCAMONDO',
    message = "Controlli l'allegato alla mail che ha appena ricevuto e verifichi che le informazioni inserite siano corrette. In caso contrario ci contatti telefonicamente allo 0736343440 oppure allo 0736336339"
    } = props

  return (
    // <div className={classes.root}>
    <Alert severity="success">
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
    // </div>
  )
}
