// import axios from 'axios'
// import getErrors from './commonUtils/errors'

export function getBaseUrl() {
  let url = ''
  const developmentPorts = ['3000', '3001', '3002']

  const {origin, port, hostname, protocol} = window.location
  if (!origin) {
    window.location.origin =
      protocol + '//' + hostname + (port ? ':' + port : '')
  }

  if (hostname === 'localhost') {
    if (developmentPorts.includes(port)) {
      /*url = `http://localhost:${port}/index.php`*/
      url = 'https://staging.giocamondo.it'
    } else {
      /*url = 'http://localhost:8080/index.php'*/
      url = 'https://staging.giocamondo.it'
    }
  } else {
    if (hostname === 'shop.staging.giocamondo.it') {
      url = 'https://staging.giocamondo.it'
    } else if (hostname === 'shop-bis.staging.giocamondo.it') {
      url = 'https://staging-bis.giocamondo.it'
    } else {
      url = 'https://gestionale.giocamondo.it'
    }
  }

  return url
}
