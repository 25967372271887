import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Coupon from './Coupon'
import {bonusTypes} from '../../specificUtils/configs'

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
    },
    checkboxRow: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 16,
    },
}))

export default function StepBonusPrivate(props) {
    const {
        step2BonusType,
        step2BonusName,
        step2Coupon,
        onBonusRequest,
        onEventChange,
    } = props
    const classes = useStyles()
    return (
        <React.Fragment>
            <div className="box-light boxed">
                <Grid container spacing={3} className="panel">
                    <Grid item xs={12} sm={3} className="bonus-wrapper">
                        <Typography variant="h6">Bonus</Typography>
                        <img
                            src="https://t-ec.bstatic.com/static/img/genius/genius_trial/tier-gift/1c8d6deaaabcdc179a20aefe503b0374b6ff096a.png"
                            height={100}
                            alt="bonus"
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <div className="light-box">
                            <Typography variant="h5">In gruppo costa meno</Typography>
                            <p>
                            Organizza la tua vacanza di gruppo e approfitta di sconti esclusivi! <strong>Più siete, più risparmiate:</strong>
                            <br/>
                            Se vi state iscrivendo insieme ad amici per la stessa destinazione, questa promozione prevede 3 fasce di sconto in base al numero di iscritti al vostro gruppo:
                            </p>
                            <ul>
                                <li><strong>Gruppi di 5: sconto di € 30 a persona.</strong></li>
                                <li><strong>Gruppi di 10: sconto fino a € 50 a persona</strong></li>
                                <li><strong>Gruppi di 15: sconto fino a € 70 a persona</strong></li>
                            </ul>
                            <p>Regole per l'Offerta:</p>
                                <ol>
                                    <li>Indicate il nome del capogruppo alla prenotazione.</li>
                                    <li>Un unico referente invii via email la lista dei partecipanti a <a href="mailto:soggiorni@giocamondo.it" title="Invia lista dei partecipanti">soggiorni@giocamondo.it</a></li>
                                    <li>Completate le prenotazioni entro una settimana dalla prima registrazione.</li>
                                    <li>Offerta valida solo per soggiorni di gruppo, non cumulabile con altre promozioni.</li>
                                </ol>
                            <p></p>
                            <Grid container className={classes.checkboxRow}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={step2BonusType === bonusTypes.GRUPPO}
                                                onChange={onBonusRequest(bonusTypes.GRUPPO)}
                                            />
                                        }
                                        label="Voglio usufruire del Bonus Gruppi"
                                    />
                                </Grid>
                                {step2BonusType === bonusTypes.GRUPPO && (
                                    <Grid item>
                                        <TextField
                                            id="cognome"
                                            label="Cognome e Nome Referente"
                                            className={classes.textField}
                                            value={step2BonusName}
                                            onChange={onEventChange('step2BonusName')}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </div>

                        <div className="light-box">
                            <Typography variant="h5">Bonus Porta un amico</Typography>
                            <p><strong>Se parti privatamente con un amico che invece usufruisce del Bando Inpsieme puoi ricevere 50€ di sconto sulla tua camera.</strong></p>

                                <Grid container className={classes.checkboxRow}>
                                <Grid item>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={step2BonusType === bonusTypes.AMICO}
                                        onChange={onBonusRequest(bonusTypes.AMICO)}
                                    />
                                    }
                                    label="Voglio usufruire del Bonus Porta un amico"
                                />
                                </Grid>
                                {step2BonusType === bonusTypes.AMICO && (
                                <Grid item>
                                    <TextField
                                    id="cognome"
                                    label="Cognome e Nome"
                                    className={classes.textField}
                                    value={step2BonusName}
                                    onChange={onEventChange('step2BonusName')}
                                    />
                                </Grid>
                                )}
                            </Grid>
                        </div>

                        <div className="light-box">
                            <Typography variant="h5">In doppia è meglio</Typography>
                            <p>
                            Se condividi la camera con un’altra persona non appartenente al tuo nucleo familiare (Stato di Famiglia differente), potrai beneficiare di un doppio vantaggio: ti verrà applicato uno sconto di € 100,00 a camera ed inoltre non avrai il supplemento singola da pagare risparmiando di fatto fino a un massimo di € 500,00.
                            </p>
                            <Grid container className={classes.checkboxRow}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={step2BonusType === bonusTypes.DOPPIA}
                                            onChange={onBonusRequest(bonusTypes.DOPPIA)}
                                        />
                                        }
                                        label="In doppia è meglio"
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <p>
                        Le promozioni non sono cumulabili tra di loro. In fase di prenotazione è obbligatorio
indicare a quale promozione si vuole aderire.
                        </p>
                        
                    </Grid>
                </Grid>
            </div>
            <br/>
            <Coupon onChange={onEventChange('step2Coupon')} value={step2Coupon}/>
        </React.Fragment>
    )
}

StepBonusPrivate.propTypes = {
    division: PropTypes.string,
    step2bonuses: PropTypes.arrayOf(
        PropTypes.shape({
            bonustype: PropTypes.string.isRequired,
        })
    ),
    step2BonusRequested: PropTypes.bool,
    step2BonusType: PropTypes.any,
    step2BonusSurname: PropTypes.string,
    step2BonusName: PropTypes.string,
    step2Coupon: PropTypes.string,
    onBonusRequest: PropTypes.func.isRequired,
    onEventChange: PropTypes.func.isRequired,
}
