import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import {disabilityServices, disabilityTypes} from './DisabilityUtils'
import {Typography} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    dense: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginBottom: 16,
    },
    // menu: {
    //   width: 400,
    // },
}))

export default function Disability(props) {
    const classes = useStyles()
    const {
        step3Handicap,
        step3Helper,
        step3Disabilities,
        step3DisabilitiesService,
        step3NumberHelpers,
        step3DisabilityNotes,
        step3TypeHelpers,
        onFlagChange,
        onChange,
        assitanceExtraQuestions,
    } = props
    const errors = props.errors || {}
    return (
        <div className="box-light">
            <Grid container className={classes.dense} spacing={2}>
                <Grid item md={8} sm={12} xs={12}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">
                            {assitanceExtraQuestions
                                ? 'Uno dei partecipanti presenta disabilità ?'
                                : 'Il partecipante è una persona con disabilità ?'}
                        </FormLabel>

                        {assitanceExtraQuestions && (
                            <Typography>
                                Se verrà selezionato NO, o non verrà selezionata alcuna opzione,
                                i partecipanti dichiarano che NON necessitano di assistenza
                            </Typography>
                        )}
                        <RadioGroup
                            name="disable"
                            className={classes.group}
                            value={step3Handicap}
                            onChange={onFlagChange('step3Handicap')}>
                            <FormControlLabel value="si" control={<Radio/>} label="Sì"/>
                            <FormControlLabel value="no" control={<Radio/>} label="No"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                {step3Handicap === 'si' && (

                    <Grid container md={4} sm={12} xs={12}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Tipo di disabilità </FormLabel>
                                <TextField
                                    id="disability-type"
                                    select
                                    className={classes.select}
                                    value={step3Disabilities}
                                    onChange={onChange('step3Disabilities')}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal">
                                    <MenuItem value={disabilityTypes.CARROZZINA}>
                                        Disabilità motoria in carrozzina
                                    </MenuItem>
                                    <MenuItem value={disabilityTypes.MOTORIA}>
                                        Disabilità motoria
                                    </MenuItem>
                                    <MenuItem value={disabilityTypes.ALTRO}>
                                        Altra disabilità
                                    </MenuItem>
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Specifica la tipologia di servizio necessario</FormLabel>
                                <TextField
                                    id="disability-service"
                                    select
                                    className={classes.select}
                                    value={step3DisabilitiesService}
                                    onChange={onChange('step3DisabilitiesService')}
                                    error={!!errors.step3DisabilitiesService}
                                    helperText={!!errors.step3DisabilitiesService && 'Campo obbligatorio'}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal">
                                    <MenuItem value={disabilityServices.CONVENZIONALE}>
                                        Camera con bagno convenzionale
                                    </MenuItem>
                                    <MenuItem value={disabilityServices.H}>
                                        Camera con bagno per disabili
                                    </MenuItem>
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Note sulla disabilità </FormLabel>
                                <TextField
                                    id="disability-notes"
                                    multiline
                                    rowsMax="4"
                                    value={step3DisabilityNotes}
                                    className={classes.textField8}
                                    margin="normal"
                                    onChange={onChange('step3DisabilityNotes')}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <Grid container className={classes.dense} spacing={2}>
                {step3Handicap === 'si' && (
                    <React.Fragment>
                        <Grid item sm={6} xs={12}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">
                                    E' richiesto l'accompagnatore per il partecipante diversamente
                                    abile?
                                </FormLabel>
                                <RadioGroup
                                    name="disable"
                                    className={classes.group}
                                    value={step3Helper}
                                    onChange={onFlagChange('step3Helper')}>
                                    <FormControlLabel value="si" control={<Radio/>} label="Sì"/>
                                    <FormControlLabel value="no" control={<Radio/>} label="No"/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        {step3Helper === 'si' && (
                            <Grid item sm={6} xs={12}>
                                {!assitanceExtraQuestions && (
                                    <FormControl
                                        component="fieldset"
                                        className={classes.formControl}>
                                        <FormLabel component="legend">
                                            Numero accompagnatori
                                        </FormLabel>
                                        <TextField
                                            id="number-helpers"
                                            select
                                            className={classes.numbersSelect}
                                            value={step3NumberHelpers || ''}
                                            onChange={onChange('step3NumberHelpers')}
                                            // inputProps={{style: {textAlign: 'right'}}}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.numbersMenu,
                                                },
                                            }}
                                            margin="normal">
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                        </TextField>
                                    </FormControl>
                                )}

                                {assitanceExtraQuestions && (
                                    <FormControl
                                        component="fieldset"
                                        className={classes.formControl}>
                                        <FormLabel component="legend">
                                            Inserisci il nome/i dei partecipanti con disabilità
                                        </FormLabel>
                                        <TextField
                                            id="note"
                                            label="Inserisci il/i nominativo/i"
                                            className={classes.textField8}
                                            // value={step3NoteRoommate}
                                            onChange={onChange('step3ADANomiPartecipantiDisabili')}
                                            margin="normal"
                                        />
                                    </FormControl>
                                )}

                                <FormControl
                                    component="fieldset"
                                    className={classes.formControl}>
                                    <FormLabel component="legend">
                                        Tipo di accompagnatori
                                    </FormLabel>
                                    <TextField
                                        id="type-helpers"
                                        select
                                        className={classes.numbersSelect}
                                        value={step3TypeHelpers}
                                        onChange={onChange('step3TypeHelpers')}
                                        // inputProps={{style: {textAlign: 'right'}}}
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.typeMenu,
                                            },
                                        }}
                                        margin="normal">
                                        <MenuItem value={'parenti-3-grado'}>
                                            Parenti entro il III grado
                                        </MenuItem>
                                        <MenuItem value={'assistenza-giocamondo'}>
                                            Assistente Giocamondo (servizio a pagamento)
                                        </MenuItem>
                                    </TextField>
                                </FormControl>

                                {assitanceExtraQuestions && (
                                    <FormControl
                                        component="fieldset"
                                        className={classes.formControl}>
                                        <FormLabel component="legend">
                                            Inserisci il nome/i dei partecipanti che configura come
                                            accompagnatore
                                        </FormLabel>
                                        <TextField
                                            id="note"
                                            label="Inserisci il/i nominativo/i"
                                            className={classes.textField8}
                                            // value={step3NoteRoommate}
                                            onChange={onChange(
                                                'step3ADANomiPartecipantiAccompagnatori'
                                            )}
                                            margin="normal"
                                        />
                                    </FormControl>
                                )}
                            </Grid>
                        )}
                    </React.Fragment>
                )}
            </Grid>
        </div>
    )
}

//TODO:
//Salvataggio dati step3TypeHelpers

Disability.propTypes = {
    step3Handicap: PropTypes.oneOf(['si', 'no', '']),
    step3Helper: PropTypes.oneOf(['si', 'no', '']),
    step3Disabilities: PropTypes.oneOf(['carrozzina', 'motoria', 'altro', '']),
    step3DisabilitiesService: PropTypes.oneOf(['convenzionale', 'h']),
    step3NumberHelpers: PropTypes.number,
    onFlagChange: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    step3TypeHelpers: PropTypes.string,
}
