import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import StepPaymentMethod from './StepPaymentMethod'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  boxlighter: {
    background: 'rgb(255, 250, 229)',
    padding: '2%!important',
  },
}))

export default function StepBonusPrivate(props) {
  const {
    onTipo,
    onConfirm,
    onUpdatePayment,
  } = props

  const confirmBooking = async () => {
    try {
      await onUpdatePayment()
      await onConfirm()
    } catch (error) {
      console.error(error)
    }
  }

  const handleChange = event => {
    onTipo(event.target.value)
  }

  return (
    <div className="box-light boxed">
      <Grid container spacing={3} className="panel" alignItems="center">
        {/*
        @stefano 04-05-2020
         Togliere domanda al 5° step nel flusso dei PVT che selezionano uno dei 2 bonus (fratelli o amico).
         Prendo come se il tipo fosse impostato a 'sicuro' nella scelta del radio
        */}
        <>
          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="subtitle2" className="align-center">
              Segui i passaggi successivi per concludere la prenotazione.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1}></Grid>
          <StepPaymentMethod {...props} />
        </>
      </Grid>
    </div>
  )
}

StepBonusPrivate.propTypes = {
  bonuses: PropTypes.arrayOf(
    PropTypes.shape({
      bonustype: PropTypes.string.isRequired,
    })
  ),
  step2BonusRequested: PropTypes.bool,
  step2BonusType: PropTypes.any,
}
