import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import 'react-day-picker/lib/style.css'
import 'moment/locale/it'
import moment from 'moment'
import ClockIcon from "@material-ui/icons/Schedule";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Alert} from "@material-ui/lab";
import {CountdownContext} from "../contexts/CountdownContext";
import {convertCountDown} from "../commonUtils/misc";
import {stepsByName} from "../main/specificUtils/configs";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    alertIcon: {
        display: "flex",
        alignItems: "center"
    },
    noMargin: {
        margin: '0 !important'
    },
    timeLabel: {
        textAlign: 'center'
    },
}))

function GlobalAlertTimer(props) {
    const classes = useStyles();
    const countDownETA = useContext(CountdownContext);
    const {hours, minutes, seconds} = convertCountDown(countDownETA);

    const isCountDownExpiring = (seconds + (minutes * 60)) <= (60 * 5); //Se è meno di 5 minuti
    let countDownText = 'Il posto da te selezionato è stato bloccato. Porta a termine la tua prenotazione nei tempi indicati per mantenere la priorità acquisita';
    let bgColor = '#FEC204';
    let textColor = "#555";

    switch (props.activeStep) {
        case stepsByName.CUSTOMER_DATA:
            break;
        case stepsByName.BONUS:
            countDownText = 'Ti abbiamo bloccato il posto nel soggiorno e sul volo, non perdere la priorità acquisita';
            bgColor = '#ffa500';
            break;
        case stepsByName.INFORMATION:
            break;
        case stepsByName.EXTRA_SERVICES:
        /*countDownText = 'Ti abbiamo bloccato il posto nel soggiorno e sul volo, non perdere la priorità acquisita';
        bgColor = '#ffa500';
        break;*/
        case stepsByName.RECAP:
    }

    if (isCountDownExpiring) {
        countDownText = 'Affrettati a concludere la prenotazione per non cedere il posto ad altri partecipanti in coda';
        bgColor = '#F15402';
        // Color = '#FFFFFF';
    }


    const DateTimeDisplay = ({value, type}) =>
        (
            <div className={'countdown'}>
                <p>{String(value).padStart(2, '0')}</p>
                <small>{type}</small>
            </div>
        );


    return (
        <Alert id="countdown-timer" className={classes.alertIcon + (isCountDownExpiring ? ' countdown-blinking' : '')}
               variant="filled" style={{backgroundColor: bgColor, color: textColor, textAlign: 'left'}}
               icon={<ClockIcon fontSize="inherit"/>}>
            <Grid container spacing={1} direction="column">
                <div className={"show-counter"}>
                    <Typography variant={'h6'} className={classes.noMargin}>{countDownText}</Typography>
                    {!isCountDownExpiring && <div className={'counter-times'}>
                        <DateTimeDisplay value={hours} type={'Ore'}/>
                        <p>:</p>
                        <DateTimeDisplay value={minutes} type={'Min'}/>
                        <p>:</p>
                        <DateTimeDisplay value={seconds} type={'Sec'}/>
                    </div>}
                </div>
            </Grid>
        </Alert>
    );
}

GlobalAlertTimer.propTypes = {}

export default GlobalAlertTimer
