import axios from 'axios'
import getErrors from '../../commonUtils/errors'
import moment from 'moment'
import {getBaseUrl} from '../../commonUtils/api'

const http = axios.create({
  baseURL: getBaseUrl() + '/api',
})

const dateToServerString = date => {
  const mDate = moment(date)
  if (date && mDate.isValid()) {
    return mDate.format('YYYY-MM-DD')
  } else {
    return null
  }
}

const formatAdditionalParticipants = participants => {
  return participants.map(participant => {
    if (!participant) {
      return null
    }
    const {birthCity, birthDate, ...rest} = participant

    return {
      ...rest,
      birthCity: birthCity ? birthCity.name : null,
      birthDate: birthDate ? dateToServerString(birthDate) : null,
    }
  })
}

export async function getPackageGroups(destination, division, resortTheme) {
  const {data} = await http.post('formada/destinations/packages', {
    destination,
    division,
    resortTheme,
  })
  return data
}

export async function getFormTurns(webgroup, division) {
  const {data} = await http.post('formada/turns', {webgroup, division})
  return data
}

export async function getDepartureServices(id, division) {
  const {data} = await http.post('formada/package/cities', {
    package: id,
    division: division,
  })
  return data
}

export function getStructures(id, division) {
  return http
    .post('formada/package/structures', {package: id, division: division})
    .then(response => response.data)
}

export const getBookingFormNotice = async (division) => await http.get(`formada/notice/${division}`);

export async function getNations(division) {
  const {data} = await http.get(`formada/destinations/${division}`)
  return data
}

export function getPackage(id, division) {
  return http
    .get(`formada/package/${id}/${division}`)
    .then(response => response.data)
}

export async function getComuniByName(input = '') {
  let response = await http
    .get(`formada/comuni?q=${input}`)
    .then(response => response.data)

  return response.results
    ? response.results.map(item => {
        return {...item, value: item.id, label: item.name}
      })
    : []
}

export async function getComuniExactByName(input = '') {
  const response = await getComuniByName(input)
  return response.filter(
    item =>
      input &&
      item.name &&
      item.name.toLocaleLowerCase() === input.toLocaleLowerCase()
  )
}

export function getAlternativeServices(pack, alternative, division) {
  let body = {package: pack, alternative: alternative, division: division}
  return http
    .post('formada/package/alternative-services', body)
    .then(response => response.data)
}

export async function getFiscalCode({birthDate, ...body}) {
  const {data} = await http.post('formada/customers/fiscal-code', {
    birthDate: dateToServerString(birthDate),
    ...body,
  })
  return data
}

export async function getExtraServices(pack, alternative, division) {
  let body = {
    package: pack,
    alternative,
    division,
  }
  const {data} = await http.post('formada/package/extra-services', body)
  return data
}

export async function registerIntent(state, token) {
  const purchaseintent = {
    id: state.id,
    division: state.division,
    authCode: state.id ? state.authCode : null,
    currentStep: state.activeStep + 1,
    step1Nation: state.step1Nation,
    step1PackageGroup: state.step1PackageGroup,
    step1Package: state.step1Package ? state.step1Package : null,
    step1Turn: state.step1Turn,
    step1DepartureServiceItems: state.step1DepartureServiceItems,
    step1DepartureServiceIds: state.step1DepartureServiceIds,
    step1Structure: state.step1Structure,
    step1HasStructure: !!state.step1HasStructure,
    step1ParentSurname: state.step1ParentSurname,
    step1ParentName: state.step1ParentName,
    step1ParentNationality: state.step1ParentNationality,
    step1ParentBirthCity: state.step1ParentBirthCity
      ? state.step1ParentBirthCity.name
      : null,
    step1ParentBirthProv: state.step1ParentBirthProv,
    step1ParentBirthDate: dateToServerString(state.step1ParentBirthDate),
    step1ParentGender: state.step1ParentGender,
    step1ParentFiscalCode: state.step1ParentFiscalCode,
    step1ParentStreetAddress: state.step1ParentStreetAddress,
    step1ParentResidenceCity: state.step1ParentResidenceCity,
    step1ParentCAP: state.step1ParentCAP,
    step1ParentProv: state.step1ParentProv,
    step1ParentEmail: state.step1ParentEmail,
    step1ParentPhone: state.step1ParentPhone,
    step1ParentPhoneAlt: state.step1ParentPhoneAlt,
    step1ParentBirthNation: state.step1ParentBirthNation,
    step1ParentForeignBirthCity: state.step1ParentForeignBirthCity,
    step1Participants: formatAdditionalParticipants(state.step1Participants),
    step1Questions: state.step1Questions.join(','),
    variablePracticeInpsNumber: state.variablePracticeInpsNumber,
    step12017Question: state.step12017Question,
    step12018Question: state.step12018Question,
    step12019Question: state.step12019Question,
    step12020Question: state.step12020Question,
    step12021Question: state.step12021Question,
    step1NeverQuestion: state.step1NeverQuestion,
    step1Confirmed: state.activeStep === 0,
    step1IceFullName: state.step1IceFullName,
    step1IceCellPhone: state.step1IceCellPhone,
    step1Time: state.step1Time,
    step2Participation: state.step2Participation,
    step2InpsContributeQuantity: state.step2InpsContributeQuantity,
    step2InpsServiceId: state.step2InpsServiceId,
    step2Grade: state.step2Grade,
    step2BonusType: state.step2BonusType,
    step2BonusId: state.step2BonusId,
    step2BonusRequested: state.step2BonusRequested,
    step2BonusSurname: state.step2BonusSurname,
    step2BonusName: state.step2BonusName,
    step2Coupon: state.step2Coupon,
    step2AziendaConvenzionataNote: state.step2AziendaConvenzionataNote,
    step2Confirmed: state.activeStep === 1,
    step2Time: state.step2Time,
    step3Handicap: state.step3Handicap === 'si',
    step3Helper: state.step3Helper === 'si',
    step3TypeHelpers: state.step3TypeHelpers,
    step3ADANomiPartecipantiDisabili: state.step3ADANomiPartecipantiDisabili,
    step3ADANomiPartecipantiAccompagnatori:
      state.step3ADANomiPartecipantiAccompagnatori,
    step3Intolerance: state.step3Intolerance === 'si',
    step3IntoleranceText: state.step3IntoleranceText,
    step3IntoleranceType: state.step3IntoleranceType,
    // step3MedicalCert: state.step3MedicalCert === 'si',
    step3NoteRoommate: state.step3NoteRoommate,
    step3RoommateSurname: state.step3RoommateSurname,
    step3RoommateName: state.step3RoommateName,
    step3NoteLeaving: state.step3NoteLeaving,
    step3Disabilities: state.step3Disabilities,
    step3DisabilitiesService: state.step3DisabilitiesService,
    step3DisabilityNotes: state.step3DisabilityNotes,
    step3AdditionalNeeds: state.step3AdditionalNeeds,
    step3NumberHelpers: state.step3NumberHelpers,
    step3Confirmed: state.activeStep === 2,
    step3Time: state.step3Time,
    step4Nation: state.step4Nation,
    step4PackageGroup: state.step4PackageGroup,
    step4Turn: state.step4Turn,
    step4Package: state.step4Package ? state.step4Package : null,
    step4DepartureServiceItems: state.step4DepartureServiceItems,
    step4DepartureServiceIds: state.step4DepartureServiceIds,
    step4Structure: state.step4Structure,
    step4Privacy: state.step4Privacy,
    step4ExtraServices: state.step4ExtraServices
      ? state.step4ExtraServices.join()
      : null,
    step4Marketing: state.step4Marketing === 'si',
    step4Profilation: state.step4Profilation === 'si',
    step4Profilation2: state.step4Profilation2 === 'si',
    step4Exclusivity: state.step4Exclusivity,
    step4Confirmed: state.activeStep === 3,
    step4Time: state.step4Time,
    step5NewReservation: state.step5NewReservation,
  }

  try {
    const config = {}
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      }
    }
    const body = {purchase_intent_ada: purchaseintent}
    if (state.id && state.authCode) {
      // step > 0
      if (token && state.originPracticeId) {
        // case precompiled
        const {data} = await http.put(
          `member/purchaseIntent/${state.id}/update`,
          body,
          config
        )
        return {result: true, data}
      }
      // normal case
      const {data} = await http.put(
        `formada/purchaseintent/${state.id}`,
        body,
        config
      )
      return {result: true, data}
    } else {
      // step 0
      if (token && state.originPracticeId) {
        // case precompiled
        const {data} = await http.post(
          `member/practice/${state.originPracticeId}/saveNew`,
          body,
          config
        )
        return {result: true, data}
      }
      // normal case
      const {data} = await http.post('formada/purchaseintent', body, config)
      return {result: true, data}
    }
  } catch (e) {
    console.error(e.response)
    const response = e.response
    const genericError = {
      result: false,
      data: 'Errore non previsto, si prega di riprovare in seguito.',
    }
    if (!response) {
      return genericError
    }

    switch (response.status) {
      // case 500:
      //   if (response && response.data && response.data.error && response.data.error.exception && response.data.error.exception.length > 0) {
      //     return {
      //       result: false,
      //       data: response.data.error.exception[0].message
      //     };
      //   } else {
      //     return genericError;
      //   }
      case 400:
        return {
          result: false,
          data: getErrors(response.data.errors),
        }
      default:
        return genericError
    }
  }
}

export async function getCurrentPrice(params) {
  const body = {
    packagePrice: params.step1Subtotal,
    INPSServices: params.step2InpsServiceId,
    extraServices: params.step4ExtraServices
      ? params.step4ExtraServices.join()
      : null,
    bonusServices: params.step2BonusId,
  }
  const {data} = await http.post('formada/currentpricewithdeposit', body)
  return data
}

export const getProgramTypologies = async () => []

// #region payments
// export async function getPaymentClientToken(practice) {
//   const body = {
//     practice,
//   }
//   const {data} = await http.post('payments/token', body)
//   return data
// }

export async function payPractice(
  bankTransfer = false,
  paypalTransfer = false,
  nonce,
  practice,
  division
) {
  const body = {
    // NB: il flag banktransfer è stato inspiegabilmente implementato come stringa sulle api
    banktransfer: `${bankTransfer}`,
    paypaltransfer: `${paypalTransfer}`,
    payment_method_nonce: nonce,
    division,
    practice,
  }
  try {
    // console.log('invio body:',body);
    const {data} = await http.post('payments/payPractice', body)
    return data
  } catch (error) {
    const message =
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.exception &&
      error.response.data.error.exception.length > 0
        ? error.response.data.error.exception[0].message
        : 'Errore generico'
    //console.log(error.response.data.error.exception[0].message)
    throw new Error(message)
  }
}

export async function updatePaymentData(practice, partecipazione, tipo) {
  const body = {
    practice,
    partecipazione,
    tipo,
  }
  const {data} = await http.post('payments/updatePaymentData', body)
  return data
}

export async function paymentConfirm(practice) {
  const body = {practice}
  const {data} = await http.post('payments/confirm', body)
  return data
}

export async function getPaypalClientId(practice) {
  const body = {practice}
  const {data} = await http.post('payments/paypal/api', body)
  return data
}

export async function finalizePaypalOrder(practice, orderID) {
  const body = {practice, orderID}
  const {data} = await http.post('payments/paypal/finalize', body)
  return data
}

export async function markPaymentError(practice, error = {}) {
  let response
  if (typeof error !== 'string') {
    response = JSON.stringify(error)
  } else {
    response = error
  }
  const body = {
    practice,
    response,
  }
  const {data} = await http.post('payments/paypal/markAsError', body)
  return data
}
// #endregion

// #region authentication
export const signIn = async (email, password) => {
  const body = {
    username: email,
    password,
  }
  const {data} = await http.post('member/login', body)
  return data
}

export const getUserByToken = async token => {
  const headers = {Authorization: `Bearer ${token}`}

  const {data} = await http.get('member/me', {headers})
  return data
}

export const signUp = async (email, password, userData) => {
  const body = {
    // firstName: userData.firstName,
    // lastName: userData.lastName,
    // birthPlace: userData.birthPlace,
    // birthNation: userData.birthNation,
    // foreignBirthCity: userData.foreignBirthCity,
    // birthDate: userData.birthDate,
    // fiscalCode: userData.fiscalCode,
    // mobilePhone: userData.mobilePhone,
    // privacyAccepted: userData.privacyAccepted,
    // marketingAccepted: userData.marketingAccepted,
    ...userData,
    email1: email,
    password,
    gender: userData.gender === 'F' ? 'F' : 'Z',
  }
  const {data} = await http.post('member/register', body)
  return data
}
// #endregion

// #region precompiled
export async function getPurchaseIntentFromPracticeId(token, practiceId) {
  const headers = {Authorization: `Bearer ${token}`}

  const {data} = await http.get(
    `member/practice/${practiceId}/purchaseIntent`,
    {headers}
  )
  return data
}
// #endregion
