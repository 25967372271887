import React from 'react'
import SidebarWrapper from '../SidebarWrapper'

export default function Recap(props) {
    return (
        <div className="content-summary">
            <div className="text-center msg-confirm">
                <div className="container-msg">
                    <p>Di seguito il riepilogo della sua prenotazione</p>
                </div>
                <SidebarWrapper/>
            </div>
        </div>
    )
}
